import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PanelDoutIgnitionControl from "./PanelDoutIgnitionControl";
import PanelGreenDriving from "./PanelGreenDriving";
import PanelIgnitionCounter from "./PanelIgnitionCounter";
import PanelNetworkJamming from "./PanelNetworkJamming";
import PanelOverSpeeding from "./PanelOverSpeeding";
import PanelIDReadNotification from "./PanelIDReadNotification";
import PanelGNSSJamming from "./PanelGNSSJamming";
import PanelDoutCallControl from "./PanelDoutCallControl";
import PanelImmobilizer from "./PanelImmobilizer";
import PanelSECO from "./PanelSECO";
import PanelDout1Type from "./PanelDout1Type";
import PanelAuthorizedIDList from "./PanelAuthorizedIDList";
import { getCommandSettingsById } from "../../../services/axios";
import { socket } from "../../../socket";


const Miscellaneous = () => {

  const token = useSelector((state) => state.auth.token);
  
  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
    gsmNumbers: getData?.state?.gsmNumbers,
  }

  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <PanelGreenDriving token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelImmobilizer token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelSECO token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelOverSpeeding token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelDoutIgnitionControl token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelDoutCallControl token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelDout1Type token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelIgnitionCounter token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelNetworkJamming token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelGNSSJamming token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelAuthorizedIDList token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>
    </div >
  );
};

export default Miscellaneous;
