import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelBeaconSettings = ({token, deviceImei, commandResponse}) => {
  
  const [beaconDetection, setBeaconDetection] = useState("")
  const [beaconMode, setBeaconMode] = useState("")
  const [beaconRecord, setBeaconRecord] = useState("")
  const [beaconPriority, setBeaconPriority] = useState("")
  const [periodMove, setPeriodMove] = useState("")
  const [periodStop, setPeriodStop] = useState("")
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendBeaconSetting = async () => {
    return sendCommonRequest(cmdType.BeaconSettings, {
      detection: beaconDetection,
      mode: beaconMode,
      record: beaconRecord,
      priority: beaconPriority,
      periodOnMove: periodMove,
      periodOnStop: periodStop
    })
  }
  
  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.BeaconSettings,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setBeaconDetection(response.detection);
    setBeaconMode(response.mode);
    setBeaconRecord(response.record);
    setBeaconPriority(response.priority);
    setPeriodMove(response.periodOnMove);
    setPeriodStop(response.periodOnStop);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.BeaconSettings) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Beacon settings"
      date={updatedDate}
      onSendAction={sendBeaconSetting}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Beacon Detection"
            value={beaconDetection}
            onChange={(e) => setBeaconDetection(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disabled" },
              { value: "1", label: "ALL" },
              { value: "2", label: "Configured" },
            ]}
          />
          <SelectBox
            name="Beacon mode"
            value={beaconMode}
            onChange={(e) => setBeaconMode(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Simple" },
              { value: "1", label: "Advanced" },
            ]}
          />
          <SelectBox
            name="Beacon Records"
            value={beaconRecord}
            onChange={(e) => setBeaconRecord(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Event" },
              { value: "1", label: "Periodic" },
            ]}
          />
          <SelectBox
            name="Beacon Record Priority"
            value={beaconPriority}
            onChange={(e) => setBeaconPriority(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "None" },
              { value: "1", label: "Low" },
              { value: "2", label: "High" },
            ]}
          />
          <TextInput
            name="Record Period on Move"
            type="number"
            value={periodMove}
            onChange={(value) => setPeriodMove(value)} />
          <TextInput
            name="Record Period on Stop"
            type="number"
            value={periodStop}
            onChange={(value) => setPeriodStop(value)} />
            
        </div>
      )} />
  );
};

export default PanelBeaconSettings;
