import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { DeviceModels, Cameras, Sensors } from "../../../utils/mockup";
import { getResponse } from "../../../services/axios";

import "./AddDevice.css";
import { Select, notification } from "antd";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { getDevices } from "../../../redux/actions/devices";

const AddDevice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [userList, setUserList] = useState([]);

  const [deviceModels, setDeviceModels] = useState([]);
  const [formData, setFormData] = useState({
    vehicleType: "Car",
    plate: "",
    imei: "",
    deviceType: "",
    model: "",
    camera: "",
    sensors: [""],
    mobileNo: "",
    user: "",
  });

  useEffect(async () => {

    var response = await getResponse("/auth/userIdList", "post");
    if (response.status === 200) {
      setUserList(response.data);
    }

    setDeviceModels(DeviceModels);
  }, []);

  const handleType = (deviceType) => {
    let models = [];
    if (deviceType == "") {
      models = DeviceModels;
    } else {
      models = DeviceModels.filter(item => item.type == deviceType);
    }
    setDeviceModels(models);
    let model = formData.model;
    if (deviceType != "" && model != "") {
      let device = DeviceModels.find(item => item.device == model);
      if (device.type != deviceType) {
        model = "";
      }
    }

    setFormData({ ...formData, deviceType: deviceType, camera: "", sensors: [""], model: model });
  };

  const handleData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNumber = (value) => {
    setFormData({ ...formData, mobileNo: value });
  };

  const handleAddSensor = () => {
    setFormData({ ...formData, sensors: [...formData.sensors, ""] });
  }
  const handleRemoveSensor = (index) => {
    let sensors = [...formData.sensors];
    sensors.splice(index, 1);
    setFormData({ ...formData, sensors: sensors });
  }
  const handleUpdateSensor = (index, value) => {
    let sensors = [...formData.sensors];
    sensors[index] = value;
    setFormData({ ...formData, sensors: sensors });
  }

  const handleModelSelect = (model) => {
    let deviceType = formData.deviceType;
    let device = DeviceModels.find(item => item.device == model);
    if (device && device.type != deviceType) {
      deviceType = device.type;
      handleType(device.type);
    }
    setFormData({ ...formData, deviceType: deviceType, model: model });
  };

  const handleUserSelect = (value) => {
    setFormData({ ...formData, user: value });
  };

  const handleSubmit = () => {
    let msg = null;
    if (formData.vehicleType.length === 0) {
      msg = "Please select Vehicle type!";
    } else if (formData.plate.length === 0) {
      msg = "Please input Plate No!";
    } else if (formData.imei.length !== 15) {
      msg = "IMEI must be 15 digits!";
    } else if (formData.deviceType.length === 0) {
      msg = "Please select Device type!";
    } else if (formData.model.length === 0) {
      msg = "Please select Device model!";
    }

    if (msg != null) {
      notification.warning({
        description: msg,
      });
      return;
    }
    final();
  };

  const final = async () => {
    const sendData = {
      vehicleType: formData.vehicleType,
      vehicleNo: formData.plate,
      deviceImei: formData.imei,
      mobileNo: formData.mobileNo,
      deviceType: formData.deviceType,
      deviceModel: formData.model,
      camera: formData.camera,
      sensors: formData.sensors,
      addClient: formData.user,
    };
    var res = await getResponse("/api/vehicles/create", "post", sendData);
    if (res?.status == 200) {
      notification.success({
        description: "New device added successfully!",
      });
      dispatch(getDevices(false));
      setTimeout(() => {
        navigate("/Devices");
      }, 1000);
    }
  };

  return (
    <div className="add-device-main w-100 h-100">
      <p>Add Device/Vehicle</p>
      <div className="add-device-div1 d-flex flex-column align-items-center p-5 mx-5">
        <div className={`d-flex w-100 mb-4 ${isMobile && "flex-column"}`}>
          <div className="left-subsub1-sub1-add-device-div1 w-50 d-flex flex-column justify-content-evenly align-items-center">
            <div className="add-input-container d-flex flex-column mb-5 position-relative">
              <label>Vehicle Type</label>
              <select
                className="normal-input"
                value={formData.vehicleType}
                name="vehicleType"
                onChange={handleData}
              >
                <option>Car</option>
                <option>Bike</option>
              </select>
              {formData.vehicleType === "Car" ? (
                <img
                  className="vehicle-icon position-absolute"
                  src="/assets/Car.svg"
                  alt="none"
                />
              ) : (
                <img
                  className="vehicle-icon position-absolute"
                  src="/assets/Bike.svg"
                  alt="none"
                />
              )}
            </div>
            <div className="add-input-container d-flex flex-column mb-5">
              <label>Plate No.</label>
              <input
                className="normal-input"
                value={formData.plate}
                name="plate"
                type="text"
                maxLength={13}
                onChange={handleData}
              />
            </div>

            <div className="add-input-container d-flex flex-column mb-5">
              <label>Add to User (Optional)</label>
              <Select
                showSearch
                placeholder="Select User"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[{ label: "Select User to Add device to User", value: "" }].concat(userList.map((item) => ({
                  label: item.lname, value: item.lname
                })))}
                value={formData.user}
                onChange={(value) => handleUserSelect(value)}
              />
            </div>
            <div className={`d-flex flex-column ${!isMobile && "p-5 sensors"}`}>
              {!isMobile && <p className="p-0">Sensors / Accessories</p>}
              <div className={`add-input-container d-flex align-items-end sensor-item ${isMobile && "flex-row"}`}
              >
                <div className="d-flex flex-column col-8 px-0">
                  <div className="d-flex flex-column mb-4">
                    <label className="d-flex py-1">Camera</label>
                    <select
                      className="normal-input"
                      value={formData.camera}
                      name="camera"
                      type="text"
                      onChange={handleData}
                    >
                      <option value={""}>Select Camera</option>
                      {Cameras.filter(item => item.type == formData.deviceType).map((item) => (
                        <option key={item.id} value={item.id}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <img
                  className="ms-4 mb-3 col-4"
                  src={
                    Cameras.filter((item) => item.id === formData.camera)[0]
                      ? Cameras.filter((item) => item.id === formData.camera)[0].path
                      : "/assets/image 11.png"
                  }
                  alt="none"
                />
              </div>
              <div className={`add-input-container d-flex align-items-end sensor-item mt-5 ${isMobile && "flex-row"}`}>
                <div className="d-flex flex-column col-8 px-0">
                  <div className="d-flex flex-column mb-4">
                    <div className="d-flex justify-content-between py-1">
                      <label>External Sensor</label>
                      <img src="/assets/addbtn2.svg" alt="none" className="cursor-pointer" onClick={handleAddSensor} />
                    </div>
                    <select
                      className="normal-input"
                      value={formData.sensors?.[0]}
                      name="sensor"
                      type="text"
                      onChange={(e) => handleUpdateSensor(0, e.target.value)}
                    >
                      <option value={""}>Select Sensor</option>
                      {Sensors.filter(item => item.type == formData.deviceType).map((item) => (
                        <option key={item.id} value={item.id}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <img
                  className="ms-4 mb-3 col-4"
                  src={
                    Sensors.filter((item) => item.id === formData.sensors?.[0])[0]
                      ? Sensors.filter((item) => item.id === formData.sensors?.[0])[0].path
                      : "/assets/sensor.svg"
                  }
                  alt="none"
                />
              </div>
              {formData.sensors?.length > 1 &&
                formData.sensors?.filter((sensor, index) => index > 0).map((sensor, index) => (
                  <div className={`add-input-container d-flex align-items-end sensor-item mt-5 ${isMobile && "flex-row"}`} key={index}>
                    <div className="d-flex flex-column col-8 px-0">
                      <div className="d-flex flex-column mb-4">
                        <div className="d-flex justify-content-between py-1">
                          <label>External Sensor</label>
                          <img src="/assets/removebtn2.svg" alt="none" className="cursor-pointer" onClick={() => handleRemoveSensor(index + 1)} />
                        </div>
                        <select
                          className="normal-input"
                          value={sensor}
                          name="sensor"
                          type="text"
                          onChange={(e) => handleUpdateSensor(index + 1, e.target.value)}
                        >
                          <option value={""}>Select Sensor</option>
                          {Sensors.filter(item => item.type == formData.deviceType).map((item) => (
                            <option key={item.id} value={item.id}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <img
                      className="ms-4 col-4"
                      src={
                        Sensors.filter((item) => item.id === sensor)[0]
                          ? Sensors.filter((item) => item.id === sensor)[0].path
                          : "/assets/sensor.svg"
                      }
                      alt="none"
                    />
                  </div>
                ))
              }



            </div>
          </div>
          {!isMobile ? (
            <div className="right-subsub1-sub1-add-device-div1 w-50 d-flex flex-column p-5">
              <p className="p-0">Device Details</p>
              <div className="d-flex">
                <div className="d-flex flex-column pe-3">
                  <div className="add-input-container d-flex flex-column mb-5">
                    <label>Device IMEI</label>
                    <input
                      className="normal-input"
                      value={formData.imei}
                      name="imei"
                      type="number"
                      onChange={handleData}
                      onInput={(e) => {
                        const value = e.target.value.slice(0, 15);
                        e.target.value = value.replace(/\D/, "");
                      }}
                    />
                  </div>
                  <div className="add-input-container d-flex flex-column mb-4">
                    <label>Device Type</label>
                    <select
                      className="normal-input"
                      value={formData.deviceType}
                      name="deviceType"
                      type="text"
                      onChange={(e) => { handleType(e.target.value); }}
                    >
                      <option value={""}>Select Device Type</option>
                      <option>Teltonika</option>
                      <option>Ruptela</option>
                    </select>
                  </div>

                  <div className="add-input-container d-flex flex-column mb-4">
                    <label>Device Model</label>
                    <Select
                      showSearch
                      placeholder="Select Model"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[{ label: "Select Device Model", value: "" }].concat(deviceModels.map((item) => ({
                        label: item.device, value: item.device
                      })))}
                      value={formData.model}
                      onChange={(value) => handleModelSelect(value)}
                    />
                  </div>
                  <div className="add-input-container d-flex flex-column mb-4">
                    <label>Mobile No.</label>
                    <div className='input-item'>
                      <PhoneNumberInput
                        value={formData.mobileNo}
                        onChange={handleNumber}
                      />
                    </div>
                  </div>
                </div>
                <img
                  className="col-4 h-100 object-fit-cover ps-4 mt-5"
                  src={
                    DeviceModels.filter((item) => item.device === formData.model)[0]
                      ? DeviceModels.filter((item) => item.device === formData.model)[0].path
                      : "/assets/chip.png"
                  }
                  alt="none"
                />
              </div>
            </div>
          ) : (
            <div className={`d-flex align-items-end ${isMobile && "w-100"}`}>
              <div className="d-flex flex-column col-8 px-0">
                <div className="add-input-container d-flex flex-column mb-4">
                  <label>Device Type</label>
                  <select
                    className="normal-input"
                    value={formData.deviceType}
                    name="deviceType"
                    type="text"
                    onChange={(e) => {
                      handleType(e.target.value);
                    }}
                  >
                    <option value={""}>Select Device Type</option>
                    <option>Teltonika</option>
                    <option>Ruptela</option>
                  </select>
                </div>
                <div className="add-input-container d-flex flex-column mb-4">
                  <label>Device Model</label>
                  <Select
                    showSearch
                    placeholder="Select Model"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={deviceModels.map((item) => ({
                      label: item.device, value: item.device
                    }))}
                    value={formData.model}
                    onChange={(value) => handleModelSelect(value)}
                  />
                </div>
              </div>
              <img
                className="ms-4 mb-3 mobile-image col-4"
                src={DeviceModels.filter((item) => item.device === formData.model)[0]
                  ? DeviceModels.filter((item) => item.device === formData.model)[0].path
                  : "/assets/chip.png"}
                alt="none"
              />
            </div>
          )}
        </div>
        <div className="sub5-right-subsub1-sub1-add-device-div1 d-flex justify-content-center w-100">
          <button className='me-5' onClick={() => navigate("/Devices")}>Cancel</button>
          <button onClick={() => handleSubmit()}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default AddDevice;
