import { socket } from "../../socket";
import { SET_USER, LOG_OUT, UPDATE_USER } from "../store/types";

const initialState = {
  user: localStorage.getItem("user") !== "undefined" ? JSON.parse(localStorage.getItem("user")) : null,
  isAuthenticated: !!localStorage.getItem("token"),
  token: localStorage.getItem("token"),
  gm: localStorage.getItem("gm") ? JSON.parse(localStorage.getItem("gm")) : null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("role", action.payload.user.role);
      localStorage.setItem("gm", JSON.stringify(action.payload.gm));

      return {
        user: action.payload.user,
        isAuthenticated: true,
        token: action.payload.token,
        gm: action.payload.gm
      };

    case UPDATE_USER:
      localStorage.setItem("user", JSON.stringify(action.payload));

      return {
        ...state,
        user: action.payload,
      };

    case LOG_OUT:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("role");

      // socket disconnect
      socket.disconnect();

      return {
        user: null,
        isAuthenticated: false,
        token: null,
        gm: null
      };

    default:
      return state;
  }
}
