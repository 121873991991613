import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelUnknownGSM = ({token, deviceImei, commandResponse}) => {
  
  const [ugns_vehicle_drt, setUGNSVehicleDRT] = useState("");
  const [ugns_vehicle_sr, setUGNSVehicleSR] = useState("");
  const [ugns_vehicle_sp, setUGNSVehicleSP] = useState("");
  const [ugns_moving_timeout, setUGNSMovingTimeout] = useState("");
  const [ugns_moving_distance, setUGNSMovingDistance] = useState("");
  const [ugns_moving_ac, setUGNSMovingAC] = useState("");
  const [ugns_moving_sc, setUGNSMovingSC] = useState("");
  const [ugns_moving_sr, setUGNSMovingSR] = useState("");
  const [ugns_moving_sp, setUGNSMovingSP] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendUnknownGSMNetwork = async () => {
    return sendCommonRequest(cmdType.UnknownGsmNetwork, {
      recTout: ugns_vehicle_drt,
      saveRec: ugns_vehicle_sr,
      sendPeriod: ugns_vehicle_sp,
      tout: ugns_moving_timeout,
      distance: ugns_moving_distance,
      angChg: ugns_moving_ac,
      speedChg: ugns_moving_sc,
      msavRec: ugns_moving_sr,
      msendPeriod: ugns_moving_sp
    })
  }

  
  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.UnknownGsmNetwork,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setUGNSVehicleDRT(response.recTout);
    setUGNSVehicleSR(response.saveRec);
    setUGNSVehicleSP(response.sendPeriod);
    setUGNSMovingTimeout(response.tout);
    setUGNSMovingDistance(response.distance);
    setUGNSMovingAC(response.angChg);
    setUGNSMovingSC(response.speedChg);
    setUGNSMovingSR(response.msavRec);
    setUGNSMovingSP(response.msendPeriod);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.UnknownGsmNetwork) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Unkown GSM Network Settings"
      description="Data acquisition parameters for unkown GSM network."
      date={updatedDate}
      onSendAction={sendUnknownGSMNetwork}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="sub-panel w36">
            <p>Vehicle on Stop</p>
            <TextInput
              name="Data Recording Timeout"
              tooltip="Time interval to acquire new record, 1 - 259200 seconds, 0 means disable recording data"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={ugns_vehicle_drt}
              onChange={(value) => setUGNSVehicleDRT(value)} />
            <TextInput
              name="Saved Records"
              tooltip="Minimal number of records in one data packet that can be send to server, 1 - 255. Has higher priopity than [Send Period]"
              type="number"
              className="justify-content-between"
              value={ugns_vehicle_sr}
              onChange={(value) => setUGNSVehicleSR(value)} />
            <TextInput
              name="Send Period"
              tooltip="Frequency of sending data to server, 0 - 259200 seconds. Has lower priority than [Saved Records]"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={ugns_vehicle_sp}
              onChange={(value) => setUGNSVehicleSP(value)} />
          </div>
          <div className="sub-panel w36">
            <p>Vehicle Moving</p>
            <TextInput
              name="Timeout"
              tooltip="Time interval to acquire new record, 1 - 259200 seconds, 0 means disable recording data by min period"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={ugns_moving_timeout}
              onChange={(value) => setUGNSMovingTimeout(value)} />
            <TextInput
              name="Distance"
              tooltip="Minimal distance to acquire new record, 1 - 65535 meters, 0 means disable recording data by min distance"
              type="number"
              suffix="Meters"
              className="justify-content-between"
              value={ugns_moving_distance}
              onChange={(value) => setUGNSMovingDistance(value)} />
            <TextInput
              name="Angle Change"
              tooltip="Minimal angle change to acquire new record, 1 - 180 degrees, 0 means disable recording data by min angle change. Valid only if vehicle speed is more than 10 km/h"
              type="number"
              suffix="Degrees"
              className="justify-content-between"
              value={ugns_moving_ac}
              onChange={(value) => setUGNSMovingAC(value)} />
            <TextInput
              name="Speed Change"
              tooltip="Minimal speed change to acquire new record, 1 - 255 km/h, 0 means disable recording data by speed change"
              type="number"
              suffix="Km/h"
              className="justify-content-between"
              value={ugns_moving_sc}
              onChange={(value) => setUGNSMovingSC(value)} />
            <TextInput
              name="Saved Records"
              tooltip="Minimal number of records in one data packet that can be send to server, 1 - 255. Has higher priopity than [Send Period]"
              type="number"
              className="justify-content-between"
              value={ugns_moving_sr}
              onChange={(value) => setUGNSMovingSR(value)} />
            <TextInput
              name="Send Period"
              tooltip="Frequency of sending data to server, 0 - 259200 seconds. Has lower priority than [Saved Records]"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={ugns_moving_sp}
              onChange={(value) => setUGNSMovingSP(value)} />
          </div>
        </div>
      )} />
  );
};

export default PanelUnknownGSM;
