import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PanelCrashDetection from "./PanelCrashDetection";
import PanelExcessiveDetection from "./PanelExcessiveDetection";
import PanelTowingDetection from "./PanelTowingDetection";
import PanelUnplugDetection from "./PanelUnplugDetection";
import { getCommandSettingsById } from "../../../services/axios";
import { socket } from "../../../socket";

const Accelerometer = () => {

  const token = useSelector((state) => state.auth.token);
  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
    gsmNumbers: getData?.state?.gsmNumbers,
  }

  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <PanelUnplugDetection token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
      </div>

      <div className="col-md-4">
        <PanelTowingDetection token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
      </div>

      <div className="col-md-4">
        <PanelCrashDetection token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelExcessiveDetection token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
      </div>
    </div>
  );
};

export default Accelerometer;
