import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelProtocolServer = ({token, deviceImei, commandResponse}) => {
  
  const [resynchronization, setResyncronization] = useState("")
  const [ntpServer1, setNtpServer1] = useState("")
  const [ntpServer2, setNtpServer2] = useState("")

  const [updatedDate, setUpdatedDate] = useState("")

  const sendNetworkTime = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.NetworkTimeProtocalServer,
      params: {
        period: resynchronization,
        server1: ntpServer1,
        server2: ntpServer2,
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.NetworkTimeProtocalServer,
      devImei: deviceImei,
      isLast: isLast
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setResyncronization(response.period);
    setNtpServer1(response.server1);
    setNtpServer2(response.server2);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.NetworkTimeProtocalServer) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Network Time Protocol server"
      description=""
      date={updatedDate}
      onSendAction={sendNetworkTime}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <TextInput
            name="Re-synchronization Period"
            tooltip=""
            suffix="Hours"
            type="number"
            value={resynchronization}
            onChange={(value) => setResyncronization(value)} />
          <TextInput
            name="NTP Server 1"
            tooltip=""
            type="text"
            value={ntpServer1}
            onChange={(value) => setNtpServer1(value)} />
          <TextInput
            name="NTP Server 2"
            tooltip=""
            type="text"
            value={ntpServer2}
            onChange={(value) => setNtpServer2(value)} />
        </div>
      )} />
  );
};

export default PanelProtocolServer;
