import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { getResponse, uploadAvatar } from '../../../services/axios';

import "./AddUser.css";
import { UserRole } from '../../../common/common';
import { notification } from 'antd';
import PhoneNumberInput from '../../commons/input/PhoneNumberInput';
import { getCountries } from "../../../utils/globals";

const AddUser = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const role = useSelector((state) => state.auth.user.role);
    const CountryData = getCountries();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [avatarPath, setAvatarPath] = useState('');
    const [formData, setFormData] = useState({
        name: "",
        username: "",
        email: "",
        mobile: "",
        country: "",
        address: "",
        role: "",
        company: ""
    })

    useEffect(() => {
        const func = debounce(async () => {
            let temp = [];
            if (formData.country) {
                let count = 0;
                let maxCount = 1000;
                let issame = false;
                for (let i = 0; i < CountryData.length; i++) {
                    if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase() === formData.country.toLowerCase())
                        issame = true;
                    if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase().startsWith(formData.country.toLowerCase())) {
                        temp.push({
                            state: CountryData[i].city,
                            country: CountryData[i].country,
                        });
                        count++;
                    }
                    if (count > maxCount) {
                        break;
                    }
                }
                if (issame) {
                    setSuggestions([]);
                } else {
                    setSuggestions(temp);
                }
            } else {
                setSuggestions([]);
            }

        }, 500);

        func();
        return () => {
            func.cancel();
        };

    }, [formData.country])

    const handleData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleNumber = (value) => {
        setPhoneNumber(value)
    }

    const onChange = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            const result = await uploadAvatar(selectedFile);
            if (result.status == 200) {
                setAvatarPath(result.data.filePath);
            }
        }
    }

    const handleSubmit = () => {
        let msg = null;
        if (formData.name.length === 0) {
            msg = "Please input your name!";
        } else if (formData.username.length === 0) {
            msg = "Please input your username!";
        } else if (formData.email.length === 0) {
            msg = "Please input your email!";
        } else if (phoneNumber.length === 0) {
            msg = "Please input your mobile number!";
        } else if (formData.country.length === 0) {
            msg = "Please input your country";
        } else if (formData.address.length === 0) {
            msg = "Please input your address!";
        } else if (formData.role.length === 0) {
            msg = "Please select your role!";
        } else if (formData.company?.length === 0) {
            msg = "Please select your company!";
        }
        if (msg != null) {
            notification.warning({
                placement: "topRight",
                description: msg,
                duration: 3,
            });
            return;
        }
        final();
    }

    const final = async () => {
        const sendData = {
            fname: formData.name,
            lname: formData.username,
            email: formData.email,
            phone: phoneNumber,
            country: formData.country,
            address: formData.address,
            role: formData.role,
            company: formData.company,
            image: avatarPath
        }
        var res = await getResponse('/api/users/addUser', 'post', sendData);
        if (res?.status == 200) {
            notification.success({
                placement: "topRight",
                description: "User has been added successfully!",
                duration: 3,
            });
            setTimeout(() => {
                navigate("/Users");
            }, 1000);
        }
        else {
            notification.error({
                placement: "topRight",
                description: res?.data.message,
                duration: 3,
            });
        }
    }

    return (
        <div className="add-user-main w-100 h-100">
            <p>Add User</p>
            <div className='add-user-div1 d-flex justify-content-center flex-column p-5 mx-5'>
                <div className='person-add-user-sub1-add-user-div1 d-flex justify-content-center position-relative mb-5'>
                    <input type='file' className='personfile position-absolute' accept='image/*' onChange={onChange} />
                    <img crossOrigin='*' src={avatarPath ? process.env.REACT_APP_URL + '/' + avatarPath : '/assets/common_user.png'} alt='none' className='person-add-user object-fit-cover' />
                </div >
                <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                    <div className='d-flex flex-column'>
                        <label>Name</label>
                        <input className='normal-input' name='name' type='text' value={formData.name} onChange={handleData} required />
                    </div>
                    <div className='sub2-subsub1-sub1-add-user-div1 d-flex flex-column position-relative'>
                        <label>Username</label>
                        <input className='normal-input' name='username' type='text' value={formData.username} onChange={handleData} required />
                        <input className='position-absolute d-flex justify-content-center align-items-center' type='checkbox' />
                    </div>
                </div>
                <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                    <div className='d-flex flex-column'>
                        <label>Email</label>
                        <input className='normal-input' name='email' type='email' value={formData.email} onChange={handleData} required />
                    </div>
                    <div className='d-flex flex-column'>
                        <label>Mobile No.</label>
                        <div className='input-item'>
                            <PhoneNumberInput
                                value={phoneNumber}
                                onChange={handleNumber}
                            />
                        </div>
                    </div>
                </div>
                <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                    <div className='d-flex flex-column position-relative'>
                        <label>Country & City</label>
                        <input className='normal-input' name='country' type='text' value={formData.country} onChange={handleData} required />
                        {formData.country && (
                            <div className='suggestion position-absolute w-100 px-3 overflow-y-auto bg-white'>
                                {suggestions.map((cityData, index) => (
                                    <p
                                        className='my-1 cursor-pointer p-0'
                                        key={index}
                                        onClick={() => {
                                            setSuggestions([]);
                                            setFormData({ ...formData, "country": cityData.state + ", " + cityData.country });
                                        }}
                                    >
                                        {cityData.state + ", " + cityData.country}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='d-flex flex-column'>
                        <label>Address</label>
                        <input className='normal-input' name='address' type='text' value={formData.address} onChange={handleData} required />
                    </div>
                </div>
                <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                    <div className='d-flex flex-column'>
                        <label>User Type</label>
                        <select className='normal-input' name='role' type='text' value={formData.role} onChange={handleData} required>
                            <option></option>
                            {role === UserRole.Admin && <option value={UserRole.Admin}>Super Admin</option>}
                            {(role == UserRole.Admin || role == UserRole.Manager) && <option value={UserRole.Manager}>Admin</option>}
                            <option value={UserRole.Client}>Client</option>
                        </select>
                    </div>
                    <div className='d-flex flex-column'>
                        <label>Company</label>
                        <select className='normal-input' name='company' type='text' value={formData.company} onChange={handleData} required>
                            <option value="">Select Company</option>
                            {location?.state?.companies && location?.state?.companies.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
                        </select>
                    </div>
                </div>
                <div className='subsub5-sub1-add-user-div1 d-flex justify-content-center' >
                    <button className='me-5' onClick={() => navigate("/Users")}>Cancel</button>
                    <button onClick={() => handleSubmit()}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default AddUser;
