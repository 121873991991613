export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOG_OUT = "LOG_OUT";

export const GET_DEVICES = "GET_DEVICES";
export const UPDATE_DEVICE_STATUS = "UPDATE_DEVICE_STATUS";
export const UPDATE_DEVICE_GSMNUMBERS = "UPDATE_DEVICE_GSMNUMBERS";

export const SWITCH_MENU_VISIBLE = "SWITCH_MENU_VISIBLE";
export const SWITCH_SIDEBAR_VISIBLE = "SWITCH_SIDEBAR_VISIBLE";
export const TOGGLE_MAP_MODE = "TOGGLE_MAP_MODE";
