import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelDout1Type = ({token, deviceImei, commandResponse}) => {
  
  const [state, setState] = useState(false)
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAction = async () => {
    return sendCommonRequest(cmdType.Dout1OutputType, {
      state: state ? "1" : "0",
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.Dout1OutputType,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setState(parseInt(response.state) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.Dout1OutputType) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="DOUT 1 Output Type"
      description="Set initial DOUT1 state, normal or inverted"
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="input-element border-0 d-flex justify-content-center mb-3">
            <Switch
              value={state}
              onChange={(checked) => setState(checked)}
              prefix={"Normal"}
              suffix={"Inverted"}
            />
          </div>
        </div>
      )} />
  );
};

export default PanelDout1Type;
