import React, { useEffect, useState } from "react";

import SensorItem from "./SensorItem";
import Switch2 from "../../../commons/switch/Switch2";
import TextInput from "../../../commons/input/TextInput";

const SensorByMac = ({ num, values, onChange }) => {

  const [isOpen, setIsOpen] = useState(true);
  
  const handleChangeValue = (key, val) => {
    onChange({
      ...values,
      [key]: val
    });
  }

  return (
    <div className="sub-panel w-100 border-0 py-4 mb-3">
      <div className="w-100 d-flex align-items-center justify-content-between gap-2 mb-3 subtitle" style={{ paddingLeft: "10rem" }}>
        <span>EYE Sensor by MAC#{num}</span>
        <div className="line flex-grow-1"></div>
        <img
          className={`cursor-pointer mx-2 expand-collapse ${isOpen ? 'expanded' : ''}`}
          src="/assets/down2.svg"
          alt="none"
          onClick={() => setIsOpen(!isOpen)} 
        />
      </div>

      {isOpen &&
        <div className="">
          <div className="w-100 input-element border-0 d-flex justify-content-center gap-5 align-items-center mb-3">
            <span>Working Mode</span>
            <Switch2
              name={`workingMode${num}`}
              prefix="Disable"
              suffix="EYE Sensor"
              value={values?.workingMode ? 1 : 0}
              onChange={(val) => handleChangeValue("workingMode", val)} />
          </div>

          {values?.workingMode == 1 && <>
            <div className="sub-panel mb-3">
              <p>Settings</p>
              <div className="d-flex justify-content-center gap-3 align-items-center sensor-mac-setting">
                <TextInput
                  name="MAC"
                  type="text"
                  value={values?.macAddress ?? ''}
                  onChange={(val) => handleChangeValue("macAddress", val)} />
                <TextInput
                  name="Data Clear Period"
                  type="number"
                  suffix="Seconds"
                  value={values?.clearPeriod ?? ''}
                  onChange={(val) => handleChangeValue("clearPeriod", val)} />
              </div>
            </div>

            <SensorItem name={`Temperature #${num}`} values={values?.temperature} onChange={(val) => handleChangeValue("temperature", val)} />
            <SensorItem name={`Humidity #${num}`} values={values?.humidity} onChange={(val) => handleChangeValue("humidity", val)} />
            <SensorItem name={`Magnet #${num}`} values={values?.magnet} onChange={(val) => handleChangeValue("magnet", val)} />
            <SensorItem name={`Magnet Trigger Count #${num}`} values={values?.magnetCount} onChange={(val) => handleChangeValue("magnetCount", val)} />
            <SensorItem name={`Movement #${num}`} values={values?.movement} onChange={(val) => handleChangeValue("movement", val)} />
            <SensorItem name={`Movement Count #${num}`} values={values?.movementCount} onChange={(val) => handleChangeValue("movementCount", val)} />
            <SensorItem name={`Pitch #${num}`} values={values?.pitch} onChange={(val) => handleChangeValue("pitch", val)} />
            <SensorItem name={`Roll #${num}`} values={values?.roll} onChange={(val) => handleChangeValue("roll", val)} />
            <SensorItem name={`Low Battery #${num}`} values={values?.lowBattery} onChange={(val) => handleChangeValue("lowBattery", val)} />
            <SensorItem name={`Battery Voltage #${num}`} values={values?.batteryVolt} onChange={(val) => handleChangeValue("batteryVolt", val)} />
          </>
          }
        </div>
      }
    </div>
  );
};

export default SensorByMac;
