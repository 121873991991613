import "./Switch.css";

const Switch = ({ prefix, suffix, value, onChange }) => {
    return (
        <div className="switch-container d-flex justify-content-center position-relative align-items-center border-0 gap-3">
            {prefix && <p className="mb-0">{prefix}</p>}
            <label className="switch-slider-container mb-0">
                <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
                <span className="switch-slider"></span>
            </label>
            {suffix && <p className="mb-0">{suffix}</p>}
        </div>
    );
}

export default Switch;