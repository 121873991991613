import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelFOTAWeb = ({token, deviceImei, commandResponse}) => {
  
  const [fotaCheck, setFotaCheck] = useState(false);
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [period, setPeriod] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")

  const sendFOTAWeb = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.FirmwareOverTheAirWebService,
      params: {
        checked: fotaCheck ? "1" : "0",
        host: host,
        port: port,
        period: period
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.FirmwareOverTheAirWebService,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setFotaCheck(parseInt(response.checked) === 1);
    setHost(response.host);
    setPort(response.port);
    setPeriod(response.period);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.FirmwareOverTheAirWebService) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="FOTA Web"
      description=""
      date={updatedDate}
      onSendAction={sendFOTAWeb}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <p className="w-100">Firmware Over The Air web serivce</p>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={fotaCheck}
              onChange={(checked) => setFotaCheck(checked)}
              prefix={"OFF"}
              suffix={"ON"}
              />
          </div>
          <TextInput
            name="Host"
            tooltip=""
            type="text"
            value={host}
            onChange={(value) => setHost(value)} />
          <TextInput
            name="Port"
            tooltip=""
            type="number"
            value={port}
            onChange={(value) => setPort(value)} />
          <TextInput
            name="Period"
            tooltip="Min=30mins, Max=720mins"
            suffix="mins"
            type="number"
            value={period}
            options={{ min: 30, max: 720 }}
            onChange={(value) => setPeriod(value)} />
        </div>
      )} />
  );
};

export default PanelFOTAWeb;
