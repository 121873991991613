import React, { useEffect, useState } from "react";
import { CommandPriorities, cmdType, getCommandPhones } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import Try from "../try/Try";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelUnplugDetection = ({token, deviceImei, commandResponse, gsmNumbers}) => {
  
  const [unplug_priority, setUnplugPriority] = useState("");
  const [unplug_event_check, setUnplugEventCheck] = useState(false);
  const [unplug_mode, setUnplugMode] = useState("0");
  const [unplug_send_sms_check, setUnplugSendSmsCheck] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendUnplugDetection = async () => {
    return sendCommonRequest(cmdType.UnplugDetection, {

      priority: unplug_priority,
      evOnly: unplug_event_check ? "1" : "0",
      mode: unplug_mode,
      phoneNo: phoneNumber,
      sms: smsText,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.UnplugDetection,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setUnplugPriority(response.priority);
    setUnplugEventCheck(parseInt(response.evOnly) === 1);
    setUnplugMode(response.mode);
    setUnplugSendSmsCheck(parseInt(response.phoneNo) > 0);
    setPhoneNumber(response.phoneNo);
    setSmsText(response.sms);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.UnplugDetection) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Unplug Detection"
      description="Unplug detection scenario settings"
      date={updatedDate}
      onSendAction={sendUnplugDetection}
      onRefreshAction={refreshAction}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Priority"
            value={unplug_priority}
            onChange={(e) => setUnplugPriority(e.target.value)}
            options={CommandPriorities}
            tooltip={"Unplug detection scenario priority"}
          />
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={unplug_event_check}
              onChange={(checked) => setUnplugEventCheck(checked)}
              suffix={"Event Only"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"If enabled, only eventual records of unplug detection will be sent"} />
            </div>
          </div>
          <SelectBox
            name="Mode"
            value={unplug_mode}
            onChange={(e) => setUnplugMode(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Simple" },
              { value: "1", label: "Advanced" },
            ]}
            tooltip={"Unplug detection mode. Simple - according to external voltage only, advanced - external voltage and accelerometer"}
          />
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={unplug_send_sms_check}
              onChange={(checked) => setUnplugSendSmsCheck(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable sending SMS of green driving event"} />
            </div>
          </div>
          
          {unplug_send_sms_check && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}
        </div>
      )} />
  );
};

export default PanelUnplugDetection;
