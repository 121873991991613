import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { getCommandSettings } from "../../../services/axios";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelGetverCommand = ({token, deviceImei, commandResponse}) => {
  
  const [firmwareVersion, setFirmwareVersion] = useState("")
  const [gpsChipVersion, setGpsChipVersion] = useState("")
  const [hardwareModel, setHardwareModel] = useState("")
  const [imei, setImei] = useState("")
  const [initTime, setInitTime] = useState("")
  const [uptime, setUptime] = useState("")

  const [updatedDate, setUpdatedDate] = useState("")

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GetverCommandResponse,
      devImei: deviceImei,
      isLast: isLast
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setFirmwareVersion(response.firmwareVersion);
    setGpsChipVersion(response.gpsChipVersion);
    setHardwareModel(response.hardwareModel);
    setImei(response.imei);
    setInitTime(response.initTime);
    setUptime(response.uptime);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.GetverCommandResponse) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="getver command response"
      description="Readonly settings to check device firmware and hardware info"
      date={updatedDate}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5 getvers">
          <TextInput
            name="Firmware Version"
            type="text"
            options={{ disabled: true }}
            value={firmwareVersion}
            onChange={(value) => {}} />
          <TextInput
            name="GPS Chip Version"
            type="text"
            options={{ disabled: true }}
            value={gpsChipVersion}
            onChange={(value) => {}} />
          <TextInput
            name="Hardware Model"
            type="text"
            options={{ disabled: true }}
            value={hardwareModel}
            onChange={(value) => {}} />
          <TextInput
            name="IMEI"
            type="text"
            options={{ disabled: true }}
            value={imei}
            onChange={(value) => {}} />
          <TextInput
            name="Init Time"
            type="text"
            options={{ disabled: true }}
            value={initTime}
            onChange={(value) => {}} />
          <TextInput
            name="Uptime (Seconds)"
            type="text"
            options={{ disabled: true }}
            value={uptime}
            onChange={(value) => {}} />
        </div>
      )} />
  );
};

export default PanelGetverCommand;
