import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PanelDigitalOutputs from "./PanelDigitalOutputs";
import PanelDigitalInputs from "./PanelDigitalInputs";
import PanelAxisX from "./PanelAxisX";
import PanelAxisY from "./PanelAxisY";
import PanelAxisZ from "./PanelAxisZ";
import PanelBatteryLevel from "./PanelBatteryLevel";
import PanelBatteryVoltage from "./PanelBatteryVoltage";
import PanelExternalVoltage from "./PanelExternalVoltage";
import PanelAnalogueInputs from "./PanelAnalogueInput";

import "./InputOutput.css";
import { getCommandSettingsById } from "../../../services/axios";
import { socket } from "../../../socket";

const InputOutput = () => {
  const token = useSelector((state) => state.auth.token);

  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
    gsmNumbers: getData?.state?.gsmNumbers,
  }

  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <PanelDigitalOutputs token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse}/>
        <PanelDigitalInputs token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelAxisX token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
      </div>

      <div className="col-md-4">
        <PanelBatteryLevel token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelBatteryVoltage token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelAxisY token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
      </div>

      <div className="col-md-4">
        <PanelExternalVoltage token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelAnalogueInputs token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
        <PanelAxisZ token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} gsmNumbers={data?.gsmNumbers} />
      </div>
    </div >
  );
};

export default InputOutput;
