import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelAssistedGPSSettings = ({token, deviceImei, commandResponse}) => {
  
  const [allowInHome, setAllowInHome] = useState(false);
  const [allowInRoaming, setAllowInRoaming] = useState(false);
  const [allowInUnknown, setAllowInUnknown] = useState(false);
  const [agpsDuration, setAgpsDuration] = useState(1);
  const [updatedDate, setUpdatedDate] = useState("")

  const sendAction = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AssistedGPSSettings,
      params: {
        allowInHome: allowInHome ? "1" : "0",
        allowInRoaming: allowInRoaming ? "1" : "0",
        allowInUnknown: allowInUnknown ? "1" : "0",
        agpsDuration: agpsDuration,
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AssistedGPSSettings,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setAllowInHome(parseInt(response.allowInHome) === 1);
    setAllowInRoaming(parseInt(response.allowInRoaming) === 1);
    setAllowInUnknown(parseInt(response.allowInUnknown) === 1);
    setAgpsDuration(parseInt(response.agpsDuration));
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.AssistedGPSSettings) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Assisted GPS Settings"
      description="Periodically download assistance data files from server"
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-start py-3 px-5">
          <div className="input-element border-0 d-flex justify-content-start">
            <Switch
              value={allowInHome}
              onChange={(checked) => setAllowInHome(checked)}
              prefix={<span className="me-4">Allow AGPS in Home Network</span>}
              suffix={"Enabled"}
            />
          </div>
          <div className="input-element border-0 d-flex justify-content-start">
            <Switch
              value={allowInRoaming}
              onChange={(checked) => setAllowInRoaming(checked)}
              prefix={"Allow AGPS in Roaming Network"}
              suffix={"Enabled"}
            />
          </div>
          <div className="input-element border-0 d-flex justify-content-start">
            <Switch
              value={allowInUnknown}
              onChange={(checked) => setAllowInUnknown(checked)}
              prefix={"Allow AGPS in Unknown Network"}
              suffix={"Enabled"}
            />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>AGPS File Duration</span>
            <div className="btn-group me-4" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" className="btn-check" name="btnradio" id="btnradio1" checked={agpsDuration == 1} onClick={() => setAgpsDuration(1)} />
              <label className="btn " htmlFor="btnradio1">3 Days</label>

              <input type="radio" className="btn-check" name="btnradio" id="btnradio2" checked={agpsDuration == 2} onClick={() => setAgpsDuration(2)} />
              <label className="btn " htmlFor="btnradio2">6 Days</label>
            </div>
          </div>
        </div>
      )} />
  );
};

export default PanelAssistedGPSSettings;
