import React, { useEffect, useState } from "react";
import "./SystemParams.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PanelMotionDetectionSource from "./PanelMotionDetectionSource";
import PanelSleepMode from "./PanelSleepMode";
import PanelProtocolServer from "./PanelProtocolServer";
import PanelStaticNavigation from "./PanelStaticNavigation";
import PanelGNSSSource from "./PanelGNSSSource";
import PanelGetverCommand from "./PanelGetverCommand";
import PanelLedIndication from "./PanelLedIndication";
import PanelIgnitionDetection from "./PanelIgnitionDetection";
import PanelAccelerometer from "./PanelAccelerometer";
import PanelDataCodec from "./PanelDataCodec";
import PanelBatteryChargeMode from "./PanelBatteryChargeMode";
import PanelAssistedGPSSettings from "./PanelAssistedGPSSettings";
import { getCommandSettingsById } from "../../../services/axios";
import { socket } from "../../../socket";

const SystemParams = () => {
  const token = useSelector((state) => state.auth.token);

  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
  }

  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <PanelMotionDetectionSource token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelSleepMode token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelProtocolServer token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelStaticNavigation token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelGNSSSource token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelGetverCommand token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelLedIndication token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelAssistedGPSSettings token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelIgnitionDetection token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelAccelerometer token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelDataCodec token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelBatteryChargeMode token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>
    </div>
  );
};

export default SystemParams;
