import React, { useEffect, useState } from "react";

import SensorItem from "./SensorItem";
import Switch2 from "../../../commons/switch/Switch2";
import TextInput from "../../../commons/input/TextInput";

const namesCount = 25;
const eyeNames = [];
for (let i = 0; i < namesCount; i++) {
  eyeNames.push("");
}

const SensorByName = ({ values, onChange }) => {

  const handleChangeValue = (key, val) => {
    onChange({
      ...values,
      [key]: val
    });
  }

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <div className="sub-panel w36 mb-4">
        <p>General</p>
        <TextInput
          name="Data Clear Period"
          tooltip="EYE Sensor will be removed from sensor list, if this sensor has not been detected for configured amount of time. 0 - Disabled"
          type="number"
          suffix="Seconds"
          className="justify-content-between"
          value={values?.clearPeriod ?? ''}
          onChange={(value) => handleChangeValue("clearPeriod", value)} />
        <TextInput
          name="RSSI"
          tooltip="Filter EYE Sensor by received signal strength. Min=-100, Max=0"
          type="number"
          suffix="dBm"
          className="justify-content-between"
          value={values?.rssi ?? ''}
          options={{ min: -100, max: 0 }}
          onChange={(value) => handleChangeValue("rssi", value)} />
        <TextInput
          name="Record Period on Move"
          tooltip="Sensor Period saving period in seconds when device is moving state. Min=0, Max=2592000. If period configured as zero, that means record generation is disabled."
          type="number"
          suffix="Seconds"
          className="justify-content-between"
          value={values?.onMove ?? ''}
          options={{ min: 0, max: 2592000 }}
          onChange={(value) => handleChangeValue("onMove", value)} />
        <TextInput
          name="Record Period on Stop"
          tooltip="Sensor Period saving period in seconds when device is stop state. Min=0, Max=2592000. If period configured as zero, that means record generation is disabled."
          type="number"
          suffix="Seconds"
          className="justify-content-between"
          value={values?.onStop ?? ''}
          options={{ min: 0, max: 2592000 }}
          onChange={(value) => handleChangeValue("onStop", value)} />
      </div>

      <div className="w-100 d-flex flex-row justify-content-between gap-4 mb-3 sensor-settings">
        <div className="sub-panel border-0">
          <div className="text-center subtitle mb-3">EYE Sensor Settings</div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Temperature</span>
            <Switch2 name="temperature" value={values?.temperature == 1} onChange={(val) => handleChangeValue("temperature", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Humidity</span>
            <Switch2 name="humidity" value={values?.humidity == 1} onChange={(val) => handleChangeValue("humidity", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Magnet</span>
            <Switch2 name="magnet" value={values?.magnet == 1} onChange={(val) => handleChangeValue("magnet", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>MagnetCount</span>
            <Switch2 name="magnetCount" value={values?.magnetCount == 1} onChange={(val) => handleChangeValue("magnetCount", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Movement</span>
            <Switch2 name="movement" value={values?.movement == 1} onChange={(val) => handleChangeValue("movement", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>MovementCount</span>
            <Switch2 name="movementCount" value={values?.movementCount == 1} onChange={(val) => handleChangeValue("movementCount", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Pitch</span>
            <Switch2 name="pitch" value={values?.pitch == 1} onChange={(val) => handleChangeValue("pitch", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Roll</span>
            <Switch2 name="roll" value={values?.roll == 1} onChange={(val) => handleChangeValue("roll", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Low Battery</span>
            <Switch2 name="lowBattery" value={values?.lowBattery == 1} onChange={(val) => handleChangeValue("lowBattery", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Battery Voltage</span>
            <Switch2 name="batteryVolt" value={values?.batteryVolt == 1} onChange={(val) => handleChangeValue("batteryVolt", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>Name</span>
            <Switch2 name="nameEnable" value={values?.nameEnable == 1} onChange={(val) => handleChangeValue("nameEnable", val)} />
          </div>
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>MAC</span>
            <Switch2 name="macEnable" value={values?.macEnable == 1} onChange={(val) => handleChangeValue("macEnable", val)} />
          </div>
        </div>

        <div className="sub-panel border-0 overflow-auto sensor-settings" style={{ height: "43rem" }}>
          <div className="text-center subtitle mb-2">EYE Name List</div>
          <p className="text-center mb-3">List of Authorized EYE Sensors Names</p>
          {eyeNames?.map((name, index) => (
            <TextInput
              key={index}
              placeholder={`Name ${index + 1}`}
              type="text"
              value={values?.eyeNames?.[index] ?? ''}
              className="input-name-element"
              onChange={(value) => {
                let newValues = [...eyeNames];
                if (values?.eyeNames) {
                  newValues = [...values?.eyeNames];
                }
                newValues[index] = value;
                handleChangeValue("eyeNames", newValues);
              }} />
          ))}

        </div>
      </div>
    </div>
  );
};

export default SensorByName;
