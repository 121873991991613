import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelSMSReport = ({token, deviceImei, commandResponse}) => {
  
  const [allowSmsSendCheck, setAllowSmsSendCheck] = useState(false);
  const [predefined, setPredefind] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")

  const sendSmsReportSendingParams = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SmsReportSendingParams,
      params: {
        allow: allowSmsSendCheck ? "1" : "0",
        phoneNo: predefined
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SmsReportSendingParams,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setAllowSmsSendCheck(parseInt(response.allow) === 1);
    setPredefind(response.phoneNo);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.SmsReportSendingParams) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="SMS report sending parameters"
      description=""
      date={updatedDate}
      onSendAction={sendSmsReportSendingParams}
      onRefreshAction={refreshAction}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={allowSmsSendCheck}
              onChange={(checked) => setAllowSmsSendCheck(checked)}
              suffix={"Allow SMS Data Sending"}
              />
          </div>
          <TextInput
            placeholder="Predefined Phone Number"
            type="number"
            value={predefined}
            options={{ style: { textAlign: "center", padding: "6px" } }}
            onChange={(value) => setPredefind(value)} />
        </div>
      )} />
  );
};

export default PanelSMSReport;
