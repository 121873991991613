import React, { useEffect, useState } from "react";
import { CommandPriorities, cmdType, getCommandPhones } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import Try from "../try/Try";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelTowingDetection = ({token, deviceImei, commandResponse, gsmNumbers}) => {
  
  const [tow_priority, setTowPriority] = useState("");
  const [tow_record_check, setTowRecordCheck] = useState(false);
  const [tow_act_timeout, setTowActTimeOut] = useState("");
  const [tow_evt_timeout, setTowEvtTimeOut] = useState("");
  const [tow_threshold, setTowThreshold] = useState("");
  const [tow_angle, setTowAngle] = useState("");
  const [tow_duration, setTowDuration] = useState("");
  const [tow_call_check, setTowCallCheck] = useState(false);
  const [tow_send_sms_check, setTowSendSmsCheck] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendTowDetection = async () => {
    return sendCommonRequest(cmdType.TowingDetection, {
      priority: tow_priority,
      evRec: tow_record_check ? "1" : "0",
      acTout: tow_act_timeout,
      evTout: tow_evt_timeout,
      threshold: tow_threshold,
      ang: tow_angle,
      duration: tow_duration,
      callOn: tow_call_check ? "1" : "0",
      phoneNo: phoneNumber,
      sms: smsText
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.TowingDetection,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setTowPriority(response.priority);
    setTowRecordCheck(parseInt(response.evRec) === 1);
    setTowActTimeOut(response.acTout);
    setTowEvtTimeOut(response.evTout);
    setTowThreshold(response.threshold);
    setTowAngle(response.ang);
    setTowDuration(response.duration);
    setTowCallCheck(parseInt(response.callOn) === 1);
    setTowSendSmsCheck(parseInt(response.phoneNo) > 0);
    setPhoneNumber(response.phoneNo);
    setSmsText(response.sms);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.TowingDetection) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Towing Detection"
      description="Towing detection scenario settings"
      date={updatedDate}
      onSendAction={sendTowDetection}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Priority"
            value={tow_priority}
            onChange={(e) => setTowPriority(e.target.value)}
            options={CommandPriorities}
            tooltip={"Towing detection scenario priority"}
          />
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={tow_record_check}
              onChange={(checked) => setTowRecordCheck(checked)}
              suffix={"Event Only"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Generate towing event"} />
            </div>
          </div>
          <TextInput
            name="Activation Timeout"
            tooltip="Timeout to detect towing event if other conditions are met"
            type="number"
            suffix="minutes"
            value={tow_act_timeout}
            onChange={(value) => setTowActTimeOut(value)} />
          <TextInput
            name="Event Timeout"
            tooltip="Timeout to check ignition state when acceleration and angle values are reached"
            type="number"
            suffix="Seconds"
            value={tow_evt_timeout}
            onChange={(value) => setTowEvtTimeOut(value)} />
          <TextInput
            name="Threshold"
            tooltip="Value used to detect towing when ignition is OFF"
            type="number"
            suffix="mG"
            value={tow_threshold}
            onChange={(value) => setTowThreshold(value)} />
          <TextInput
            name="Angle"
            tooltip="Value used to detect towing when ignition is OFF"
            type="number"
            suffix="degrees"
            value={tow_angle}
            onChange={(value) => setTowAngle(value)} />
          <TextInput
            name="Duration"
            tooltip="Period to check acceleration and angle values"
            type="number"
            suffix="milliseconds"
            value={tow_duration}
            onChange={(value) => setTowDuration(value)} />

          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={tow_call_check}
              onChange={(checked) => setTowCallCheck(checked)}
              prefix={<span style={{ marginRight: "9rem" }}>Call</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable/disable call to one of predefined phone numbers"} />
            </div>
          </div>
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={tow_send_sms_check}
              onChange={(checked) => setTowSendSmsCheck(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable sending SMS of towing event"} />
            </div>
          </div>
          
          {tow_send_sms_check && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}
        </div>
      )} />
  );
};

export default PanelTowingDetection;
