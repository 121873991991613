import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { Select } from "antd";
import MapContainer from "../commons/maps/MapContainer";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const devices = useSelector((state) => state.devicesList.devices);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [mapCenter, setMapCenter] = useState(state?.point);
  const [selectImei, setSelectImei] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const [isShowAll, setIsShowAll] = useState(state?.point == undefined ? false : true);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setMapCenter(state?.point);
  }, [state]);

  const handleSearch = (value) => {
    const device = devices?.find(item => item.deviceImei == value);
    if (device) {
      setMapCenter({ lat: device.lat, lng: device.lng });
      setSelectImei(device.deviceImei);
    } else {
      setSelectImei(null);
    }
    setSearchText(value);
  }

  useEffect(() => {
    if (!isShowAll) {
      let count = 0;
      for (const device of devices) {
        if (device.lat != 0 && device.lng != 0) {
          count++;
        }
      }
      if (count >= 2) {
        setTimeout(() => {
          setIsShowAll(true);
        }, 1000);
      }
    }
  }, [devices]);

  return (
    <div className="map-container position-relative d-flex justify-content-center w-100">
      <Select
        showSearch
        allowClear
        className={`position-absolute vehicle-search-input ${isMobile ? 'mobile' : ''}`}
        placeholder="Search Vehicle"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={devices?.map((item) => ({
          label: `${item.vehicleName} (${item.deviceImei})`, value: item.deviceImei
        }))} 
        value={searchText}
        onChange={(value) => handleSearch(value)}
      />

      <MapContainer isShowAll={isShowAll} devices={devices} point={mapCenter} selectImei={selectImei} />
    </div>
  );
};

export default Dashboard;
