import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { DeviceModels, Cameras, Sensors } from "../../../utils/mockup";

import { Select } from "antd";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";

const ViewDevice = () => {
  const navigate = useNavigate();
  const getData = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [formData, setFormData] = useState({
    vehicleType: getData.state.vehicleType,
    plate: getData.state.plate,
    imei: getData.state.imei,
    deviceType: getData.state.device,
    model: getData.state.model,
    camera: getData.state.camera,
    sensors: getData.state.sensors ?? [""],
    mobileNo: getData.state.mobile,
    user: getData.state.user,
  });

console.log(getData.state);

  return (
    <div className="add-device-main w-100 h-100">
      <p>Edit Device/Vehicle</p>
      <div className="add-device-div1 d-flex flex-column align-items-center p-5 mx-5">
        <div className={`d-flex w-100 mb-4 ${isMobile && "flex-column"}`}>
          <div className="left-subsub1-sub1-add-device-div1 w-50 d-flex flex-column justify-content-evenly align-items-center">
            <div className="add-input-container d-flex flex-column mb-5 position-relative">
              <label>Vehicle Type</label>
              <select
                className="normal-input"
                value={formData.vehicleType}
                name="vehicleType"
                onChange={() => {}}
                disabled
              >
                <option>Car</option>
                <option>Bike</option>
              </select>
              {formData.vehicleType === "Car" ? (
                <img
                  className="vehicle-icon position-absolute"
                  src="/assets/Car.svg"
                  alt="none"
                />
              ) : (
                <img
                  className="vehicle-icon position-absolute"
                  src="/assets/Bike.svg"
                  alt="none"
                />
              )}
            </div>
            <div className="add-input-container d-flex flex-column mb-5">
              <label>Plate No.</label>
              <input
                className="normal-input"
                value={formData.plate}
                name="plate"
                type="text"
                maxLength={13}
                onChange={() => {}}
                readOnly
              />
            </div>

            <div className="add-input-container d-flex flex-column mb-5">
              <label>Add to User (Optional)</label>
              <input
                className="normal-input"
                value={formData.user}
                name="user"
                type="text"
                onChange={() => {}}
                readOnly
              />
            </div>
            <div className={`d-flex flex-column ${!isMobile && "p-5 sensors"}`}>
              {!isMobile && <p className="p-0">Sensors / Accessories</p>}
              <div
                className={`add-input-container d-flex align-items-end sensor-item ${
                  isMobile && "flex-row"
                }`}
              >
                <div className="d-flex flex-column col-8 px-0">
                  <div className="d-flex flex-column mb-4">
                    <label>Camera</label>
                    <select
                      className="normal-input"
                      value={formData.camera}
                      name="camera"
                      type="text"
                      disabled
                      onChange={() => {}}
                    >
                      <option value={""}></option>
                      {Cameras.filter(item => item.type == formData.deviceType).map((item) => (
                        <option key={item.id} value={item.id}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <img
                  className="ms-4 mb-3 col-4"
                  src={
                    Cameras.filter((item) => item.id === formData.camera)[0]
                      ? Cameras.filter((item) => item.id === formData.camera)[0].path
                      : "/assets/image 11.png"
                  }
                  alt="none"
                />
              </div>
              <div className={`add-input-container d-flex align-items-end mt-5 sensor-item ${isMobile && "flex-row"}`}>
                <div className="d-flex flex-column col-8 px-0">
                  <div className="d-flex flex-column mb-4">
                    <div className="d-flex justify-content-between py-1">
                      <label>External Sensor</label>
                    </div>
                    <select
                      className="normal-input"
                      value={formData.sensors?.[0]}
                      name="sensor"
                      type="text"
                      onChange={() => {}}
                      disabled
                    >
                      <option value={""}></option>
                      {Sensors.filter(item => item.type == formData.deviceType).map((item) => (
                        <option key={item.id} value={item.id}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <img
                  className="ms-4 mb-3 col-4"
                  src={
                    Sensors.filter((item) => item.id === formData.sensors?.[0])[0]
                      ? Sensors.filter((item) => item.id === formData.sensors?.[0])[0].path
                      : "/assets/sensor.svg"
                  }
                  alt="none"
                />
              </div>
              {formData.sensors?.length > 1 &&
              formData.sensors?.filter((sensor, index) => index > 0).map((sensor, index) => (
                <div className={`add-input-container d-flex align-items-end sensor-item mt-5 ${isMobile && "flex-row"}`} key={index}>
                  <div className="d-flex flex-column col-8 px-0">
                    <div className="d-flex flex-column mb-4">
                      <div className="d-flex justify-content-between py-1">
                        <label>External Sensor</label>
                      </div>
                      <select
                        className="normal-input"
                        value={sensor}
                        name="sensor"
                        type="text"
                        onChange={() => {}}
                        disabled
                      >
                        <option value={""}></option>
                        {Sensors.filter(item => item.type == formData.deviceType).map((item) => (
                          <option key={item.id} value={item.id}>{item.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <img
                    className="ms-4 col-4"
                    src={
                      Sensors.filter((item) => item.id === sensor)[0]
                        ? Sensors.filter((item) => item.id === sensor)[0].path
                        : "/assets/sensor.svg"
                    }
                    alt="none"
                  />
                </div>
              ))
              }

              

            </div>
          </div>
          {!isMobile ? (
            <div className="right-subsub1-sub1-add-device-div1 w-50 d-flex flex-column p-5">
              <p className="p-0">Device Details</p>
              <div className="d-flex">
                <div className="d-flex flex-column pe-3">
                  <div className="add-input-container d-flex flex-column mb-5">
                    <label>Device IMEI</label>
                    <input
                      className="normal-input"
                      value={formData.imei}
                      name="imei"
                      type="number"
                      onChange={() => {}}
                      readOnly
                    />
                  </div>
                  <div className="add-input-container d-flex flex-column mb-4">
                    <label>Device Type</label>
                    <select
                      className="normal-input"
                      value={formData.deviceType}
                      name="deviceType"
                      type="text"
                      onChange={(e) => {}}
                      disabled
                    >
                      <option value={""}></option>
                      <option>Teltonika</option>
                      <option>Ruptela</option>
                    </select>
                  </div>

                  <div className="add-input-container d-flex flex-column mb-4">
                    <label>Device Model</label>
                    <input
                      className="normal-input"
                      value={formData.model}
                      name="model"
                      type="text"
                      onChange={() => {}}
                      readOnly
                    />
                  </div>
                  <div className="add-input-container d-flex flex-column mb-4">
                    <label>Mobile No.</label>
                    <div className='input-item'>
                        <PhoneNumberInput
                            value={formData.mobileNo}
                            onChange={() => {}}
                            readOnly
                        />
                    </div>
                  </div>
                </div>
                <img
                  className="col-4 h-100 object-fit-cover ps-4 mt-5"
                  src={
                    DeviceModels.filter((item) => item.device === formData.model)[0]
                      ? DeviceModels.filter((item) => item.device === formData.model)[0].path
                      : "/assets/chip.png"
                  }
                  alt="none"
                />
              </div>
            </div>
          ) : (
            <div className={`d-flex align-items-end ${isMobile && "w-100"}`}>
              <div className="d-flex flex-column col-8 px-0">
                <div className="add-input-container d-flex flex-column mb-4">
                  <label>Device Type</label>
                  <select
                    className="normal-input"
                    value={formData.deviceType}
                    name="deviceType"
                    type="text"
                    onChange={(e) => { }}
                  >
                    <option value={""}></option>
                    <option>Teltonika</option>
                    <option>Ruptela</option>
                  </select>
                </div>
                <div className="add-input-container d-flex flex-column mb-4">
                  <label>Device Model</label>
                  <input
                    className="normal-input"
                    value={formData.model}
                    name="model"
                    type="text"
                    onChange={() => {}}
                    readOnly
                  />
                </div>
              </div>
              <img
                className="ms-4 mb-3 mobile-image col-4"
                src={DeviceModels.filter((item) => item.device === formData.model)[0]
                  ? DeviceModels.filter((item) => item.device === formData.model)[0].path
                  : "/assets/chip.png"}
                alt="none"
              />
            </div>
          )}
        </div>
        <div className="sub5-right-subsub1-sub1-add-device-div1 d-flex justify-content-center w-100">
          <button onClick={() => navigate("/Devices")}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewDevice;
