import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const idCount = 10;    // 200
const initialValues = [];
for (let i = 0; i < idCount; i++) {
  initialValues.push("");
}
const PanelAuthorizedNumbers = ({token, deviceImei, commandResponse}) => {
  
  const [values, setValues] = useState(initialValues);
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAction = async () => {
    return sendCommonRequest(cmdType.AuthorizedNumbers, {
      values: values
    });
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AuthorizedNumbers,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    let tempValues = [];
    for (let i = 0; i < idCount; i++) {
      let val = response.values?.[`p${i}`] ? response.values?.[`p${i}`] : '';
      tempValues.push(val);
    }
    setValues(tempValues);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.AuthorizedNumbers) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Authorized Numbers"
      description="List of Authorized Numbers to receive SMS and Calls from"
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="position-relative d-flex flex-column align-items-center py-3 px-5 overflow-auto">
          {values?.map((val, index) => (
            <TextInput
              key={index}
              placeholder={`Number ${index+1}`}
              type="text"
              value={val}
              options={{ style: { textAlign: "center", padding: "6px" } }}
              onChange={(value) => {
                let newValues = [...values];
                newValues[index] = value;
                setValues(newValues);
              }} />
          ))}
        </div>
      )} />
  );
};

export default PanelAuthorizedNumbers;
