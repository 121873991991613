import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";
import { useDispatch } from "react-redux";
import { UPDATE_DEVICE_GSMNUMBERS } from "../../../redux/store/types";


const initialNumbers = [];
for (let i = 0; i < 10; i++) {
  initialNumbers.push("");
}

const PanelPhoneNumbers = ({token, deviceImei, commandResponse}) => {

  const dispatch = useDispatch();

  const [phones, setPhones] = useState(initialNumbers);
  const [updatedDate, setUpdatedDate] = useState("")

  const sendPhoneNumbers = async () => {
    let params = {};
    for (let i = 0; i < 10; i++) {
      params[`p${i}`] = phones[i];
    }

    const sendCommandData =
    {
      token: token,
      type: cmdType.PhoneNumbers,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.PhoneNumbers,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setPhones(response.numbers);
    
    dispatch({
      type: UPDATE_DEVICE_GSMNUMBERS,
      payload: {
        deviceImei: deviceImei,
        gsmNumbers: response.numbers
      }
    });
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.PhoneNumbers) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="GSM Predefined Numbers"
      description="List of predefined numbers for SMS event sending and making Calls"
      date={updatedDate}
      onSendAction={sendPhoneNumbers}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          {phones.map((phone, index) => (
            <TextInput
              key={index}
              placeholder={`Phone Number ${index+1}`}
              type="text"
              value={phone}
              options={{ style: { textAlign: "center", padding: "6px" } }}
              onChange={(value) => {
                let newValues = [...phones];
                newValues[index] = value;
                setPhones(newValues);
              }} />
          ))}
        </div>
      )} />
  );
};

export default PanelPhoneNumbers;
