import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelBLESerialEncryption = ({token, deviceImei, commandResponse}) => {
  
  const [encryptionKey, setEncryptionKey] = useState("")
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAction = async () => {
    return sendCommonRequest(cmdType.BLESerialEncryption, {
      encryptionKey: encryptionKey,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.BLESerialEncryption,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setEncryptionKey(response.encryptionKey);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.BLESerialEncryption) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="BLE Serial Encryption"
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <TextInput
            name="AES Encryption Key"
            type="text"
            tooltip="AES Encryption Key in HEX format. If are all set to 0 or empty, encryption is empty"
            value={encryptionKey}
            onChange={(value) => setEncryptionKey(value)} />
        </div>
      )} />
  );
};

export default PanelBLESerialEncryption;
