import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import "./ViewCompany.css";

const ViewCompany = () => {
  const getData = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: getData.state.companyInfo.name,
    owner: getData.state.companyInfo.owner,
    email: getData.state.companyInfo.email,
    mobile: getData.state.companyInfo.mobile,
    country: getData.state.companyInfo.country,
    address: getData.state.companyInfo.address,
    logo: getData.state.companyInfo.logo,
  });


  return (
    <div className="view-company-main w-100 h-100">
      <p>View Company</p>
      <div className="view-company-div1 d-flex justify-content-between flex-column p-5 mx-5" style={{ height: "calc(100vh - 200px)" }}>
        <div className="d-flex justify-content-center flex-column">
        <div className="person-view-company-sub1-view-company-div1 d-flex justify-content-center position-relative mb-5">
            <img
              crossOrigin='*'
              src={formData.logo ? process.env.REACT_APP_URL + '/' + formData.logo : '/assets/uber.png'}
              alt="none"
              className="person-edit-company"
            />
        </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
              <label>Company Name</label>
              <input className='normal-input' value={formData.name} type='text' name="name" readOnly onChange={() => {}} />
          </div>
          <div className="sub2-subsub1-sub1-view-company-div1 d-flex flex-column position-relative">
              <label>Owner Name</label>
              <input className='normal-input' value={formData.owner} type='text' name="owner" readOnly onChange={() => {}} />
          </div>
        </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
            <label>Email</label>
              <input className='normal-input' value={formData.email} type='email' name="email" readOnly onChange={() => {}} />
          </div>
          <div className='d-flex flex-column'>
            <label>Mobile No.</label>
              <div className='input-item'>
                <PhoneNumberInput
                  value={formData.mobile}
                  readOnly={true}
                  onChange={() => {}}
                />
            </div>
          </div>
        </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5'>
          <div className='d-flex flex-column'>
              <label>Country & City</label>
              <input className='normal-input' name='country' type='text' value={formData.country} readOnly onChange={() => {}} />
          </div>
          <div className='d-flex flex-column'>
            <label>Address</label>
              <input className='normal-input' type='text' value={formData.address} name="address" readOnly onChange={() => {}} />
            </div>
          </div>
        </div>
        <div className="subsub5-sub1-edit-user-div1 d-flex justify-content-center">
          <button className='me-5' onClick={() => navigate("/Company")}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewCompany;
