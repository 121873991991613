import { useRef, useState } from "react";
import Try from "../../commads-settings/try/Try";

import "./TextInput.css";

const TextInput = ({ name, tooltip, suffix, className="", type="text", placeholder="", options={}, value, onChange }) => {

    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className={`w-100 d-flex justify-content-center align-items-center mb-3 position-relative ${className}`}>
            <div className={`text-input-container bg-white d-flex flex-column justify-content-between position-relative align-items-center ${isFocused ? 'highlight' : ''}`}
                onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} style={{ paddingRight: (suffix ? "6rem" : "1rem") }}
                onClick={() => inputRef.current.focus()}
            >
                {name && <div className="w-100 text-secondary">{name}</div>}
                <input 
                    ref={inputRef}
                    className="w-100 border-0" 
                    type={type} 
                    value={value} 
                    placeholder={placeholder} 
                    {...options} 
                    onChange={(e) => onChange(e.target.value)} />
                {suffix && <div className="position-absolute bottom-0" style={{ right: "1rem" }}>{suffix}</div>}
            </div>
            {tooltip && 
                <div className="position-absolute text-input-tooltip" style={{right: 0}}>
                    <Try text={tooltip} />
                </div>
            }
        </div>
    );
}

export default TextInput;