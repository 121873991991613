import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelMQTTSettings = ({token, deviceImei, commandResponse}) => {
  
  const [type, setType] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [dataTopic, setDataTopic] = useState("");
  const [commandsTopic, setCommandsTopic] = useState("");
  const [primarySASKey, setPrimarySASKey] = useState("");
  const [secondarySASKey, setSecondarySASKey] = useState("");
  const [SASPolicyName, setSASPolicyName] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")

  const sendAction = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.MQTTSettings,
      params: {
        type: type,
        deviceId: deviceId,
        dataTopic: dataTopic,
        commandsTopic: commandsTopic,
        primarySASKey: primarySASKey,
        secondarySASKey: secondarySASKey,
        SASPolicyName: SASPolicyName,
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.MQTTSettings,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setType(response.type);
    setDeviceId(response.deviceId);
    setDataTopic(response.dataTopic);
    setCommandsTopic(response.commandsTopic);
    setPrimarySASKey(response.primarySASKey);
    setSecondarySASKey(response.secondarySASKey);
    setSASPolicyName(response.SASPolicyName);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.MQTTSettings) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="MQTT Settings"
      description="Select MQTT Client based on used IoT Service"
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox 
            name="MQTT Client Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            options={[ 
              { value: "", label: "" },
              { value: "0", label: "AWS IoT Shadow" },
              { value: "1", label: "AWS IoT Custom" },
              { value: "2", label: "Azure Cloud" },
            ]}
            />
          <TextInput
            name="Device ID"
            tooltip="If left Empty Device IMEI will be used, Max Length=21"
            type="text"
            value={deviceId}
            onChange={(value) => setDeviceId(value)} />
          {type == 1 &&
            <>
            <TextInput
              name="Data Topic"
              tooltip="Topic Name used for sending Data from tracking device"
              type="text"
              value={dataTopic}
              onChange={(value) => setDataTopic(value)} />
            <TextInput
              name="Commands Topic"
              tooltip="Topic name used for sending command to tracking device"
              type="text"
              value={commandsTopic}
              onChange={(value) => setCommandsTopic(value)} />
            </>
          }
          
          {type == 2 &&
            <>
            <TextInput
              name="Primary SAS key"
              tooltip=""
              type="text"
              value={primarySASKey}
              onChange={(value) => setPrimarySASKey(value)} />
            <TextInput
              name="Secondary SAS key"
              tooltip=""
              type="text"
              value={secondarySASKey}
              onChange={(value) => setSecondarySASKey(value)} />
            <TextInput
              name="SAS Policy Name"
              tooltip=""
              type="text"
              value={SASPolicyName}
              onChange={(value) => setSASPolicyName(value)} />
            </>
          }
        </div>
      )} />
  );
};

export default PanelMQTTSettings;
