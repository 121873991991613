import React, { useEffect, useState } from "react";
import { formatDateTime, sleep } from "../../../utils/globals";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import { useMediaQuery } from "@mui/material";
import { sendCommandMsg } from "../../../socket";

const PanelDigitalOutputs = ({ token, deviceImei, commandResponse }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [updatedDate, setUpdatedDate] = useState("")

  const [timeout1, setTimeout1] = useState(null);
  const [timeout2, setTimeout2] = useState(null);
  const [timeout3, setTimeout3] = useState(null);
  const [timeout4, setTimeout4] = useState(null);

  const [dout1, setDout1] = useState(null);
  const [dout2, setDout2] = useState(null);
  const [dout3, setDout3] = useState(null);
  const [dout4, setDout4] = useState(null);

  const [din1, setDin1] = useState("");
  const [din2, setDin2] = useState("");
  const [din3, setDin3] = useState("");
  const [din4, setDin4] = useState("");

  const [ain1, setAin1] = useState(null);
  const [ain2, setAin2] = useState(null);
  const [ain3, setAin3] = useState(null);
  const [ain4, setAin4] = useState(null);

  const sendDigitalOutput = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.DigitalOutput,
      params: {
        out1: dout1,
        out2: dout2,
        out3: dout3,
        out4: dout4,
        timeout1: (timeout1 && timeout1.length > 0 ? timeout1 : "?"),
        timeout2: (timeout2 && timeout2.length > 0 ? timeout2 : "?"),
        timeout3: (timeout3 && timeout3.length > 0 ? timeout3 : "?"),
        timeout4: (timeout4 && timeout4.length > 0 ? timeout4 : "?"),
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshOutput = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.DigitalOutput,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setDout1(!response.dout1 ? null : parseInt(response.dout1));
    setDout2(!response.dout2 ? null : parseInt(response.dout2));
    setDout3(!response.dout3 ? null : parseInt(response.dout3));
    setDout4(!response.dout4 ? null : parseInt(response.dout4));
    setTimeout1(response.timeout1);
    setTimeout2(response.timeout2);
    setTimeout3(response.timeout3);
    setTimeout4(response.timeout4);
    setDin1(!response.din1 ? null : parseInt(response.din1) === 1 ? "High" : "Low");
    setDin2(!response.din2 ? null : parseInt(response.din2) === 1 ? "High" : "Low");
    setDin3(!response.din3 ? null : parseInt(response.din3) === 1 ? "High" : "Low");
    setDin4(!response.din4 ? null : parseInt(response.din4) === 1 ? "High" : "Low");
    setAin1(response.ain1);
    setAin2(response.ain2);
    setAin3(response.ain3);
    setAin4(response.ain4);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.DigitalOutput) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Digital Inputs/Outputs"
      description="Change state of digital outputs"
      date={updatedDate}
      onSendAction={sendDigitalOutput}
      onRefreshAction={refreshOutput}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-column align-items-center py-3" style={{ paddingLeft: isMobile ? "2rem" : "14rem" }}>
          {dout1 !== null &&
            <div className="d-flex justify-content-start align-items-center w-100 mb-3">
              <Switch
                value={dout1 === 1}
                onChange={(checked) => setDout1(checked ? 1 : 0)}
                prefix={<span className="me-4">Output-1</span>}
              />
              <div className="sub1-subsub2-sub1-subsub2-sub1-subsub1-sub1-input-output-div3">
                <input type="number" placeholder="Timeout" onChange={(e) => setTimeout1(e.target.value)} disabled={dout1 === 0} />
                <p style={{ display: (timeout1 && timeout1.length > 0) ? "block" : "none" }}>SEC</p>
              </div>
            </div>
          }
          {dout2 !== null &&
            <div className="d-flex justify-content-start align-items-center w-100 mb-3">
              <Switch
                value={dout2 === 1}
                onChange={(checked) => setDout2(checked ? 1 : 0)}
                prefix={<span className="me-4">Output-2</span>}
              />
              <div className="sub1-subsub2-sub1-subsub2-sub1-subsub1-sub1-input-output-div3">
                <input type="number" placeholder="Timeout" onChange={(e) => setTimeout2(e.target.value)} disabled={dout2 === 0} />
                <p style={{ display: (timeout2 && timeout2.length > 0) ? "block" : "none" }}>SEC</p>
              </div>
            </div>
          }
          {dout3 !== null &&
            <div className="d-flex justify-content-start align-items-center w-100 mb-3">
              <Switch
                value={dout3 === 1}
                onChange={(checked) => setDout3(checked ? 1 : 0)}
                prefix={<span className="me-4">Output-3</span>}
              />
              <div className="sub1-subsub2-sub1-subsub2-sub1-subsub1-sub1-input-output-div3">
                <input type="number" placeholder="Timeout" onChange={(e) => setTimeout3(e.target.value)} disabled={dout3 === 0} />
                <p style={{ display: (timeout3 && timeout3.length > 0) ? "block" : "none" }}>SEC</p>
              </div>
            </div>
          }
          {dout4 !== null &&
            <div className="d-flex justify-content-start align-items-center w-100 mb-3">
              <Switch
                value={dout4 === 1}
                onChange={(checked) => setDout4(checked ? 1 : 0)}
                prefix={<span className="me-4">Output-4</span>}
              />
              <div className="sub1-subsub2-sub1-subsub2-sub1-subsub1-sub1-input-output-div3">
                <input type="number" placeholder="Timeout" onChange={(e) => setTimeout4(e.target.value)} disabled={dout4 === 0} />
                <p style={{ display: (timeout4 && timeout4.length > 0) ? "block" : "none" }}>SEC</p>
              </div>
            </div>
          }

          {din1 !== null &&
            <div className="d-flex justify-content-left align-items-center w-100 mb-3">
              <span className="din-label">DIN-1</span>
              <div className={`din-shape ${din1}`}></div>
              <span className="din-value">{din1}</span>
            </div>
          }
          {din2 !== null &&
            <div className="d-flex justify-content-left align-items-center w-100 mb-3">
              <span className="din-label">DIN-2</span>
              <div className={`din-shape ${din2}`}></div>
              <span className="din-value">{din2}</span>
            </div>
          }
          {din3 !== null &&
            <div className="d-flex justify-content-left align-items-center w-100 mb-3">
              <span className="din-label">DIN-3</span>
              <div className={`din-shape ${din3}`}></div>
              <span className="din-value">{din3}</span>
            </div>
          }
          {din4 !== null &&
            <div className="d-flex justify-content-left align-items-center w-100 mb-3">
              <span className="din-label">DIN-4</span>
              <div className={`din-shape ${din4}`}></div>
              <span className="din-value">{din4}</span>
            </div>
          }
        </div>
      )} />
  );
};

export default PanelDigitalOutputs;
