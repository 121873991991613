import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PanelIncomingCallAction from "./PanelIncomingCallAction";
import PanelPhoneNumbers from "./PanelPhoneNumbers";
import PanelSMSEvent from "./PanelSMSEvent";
import PanelSMSReport from "./PanelSMSReport";
import PanelAuthorizedNumbers from "./PanelAuthorizedNumbers";
import { getCommandSettingsById } from "../../../services/axios";
import { socket } from "../../../socket";

const Configuration = () => {
  const token = useSelector((state) => state.auth.token);

  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
  }

  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <PanelSMSReport token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelIncomingCallAction token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelPhoneNumbers token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelSMSEvent token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelAuthorizedNumbers token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>
    </div>
  );
};

export default Configuration;
