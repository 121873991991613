import React from "react";

import { formatDateTimeSec } from "../../utils/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faXmark } from "@fortawesome/free-solid-svg-icons";

const TelemetryItem = ({ id, index, name, heading, value, updatedAt, isDisabled, onDelete, onChangePosition, className = "" }) => {
    return (
        <div className={`d-flex justify-content-between align-items-center position-relative telemetry-item ${className} ${isDisabled ? 'disabled' : ''}`}>
            <span>{name}</span>
            <span className="">{value}</span>
            <span className="update-date">{formatDateTimeSec(updatedAt)}</span>

            <div className="item-action">
                <a className="cursor-pointer" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faEllipsisVertical} size="xl" color="gray" />
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" onClick={onDelete}>
                        <FontAwesomeIcon icon={faXmark} size="xl" color="red" /> <span className="ms-3">Remove Telemetry Item</span>
                    </a>
                </ul>
            </div>
        </div>
    );
}

export default TelemetryItem;