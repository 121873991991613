import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const idCount = 20;     // 4000
const initialIds = [];
for (let i = 0; i < idCount; i++) {
  initialIds.push("");
}
const PanelAuthorizedIDList = ({ token, deviceImei, commandResponse }) => {

  const [authorizedIds, setAuthorizedIds] = useState(initialIds);
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAction = async () => {
    return sendCommonRequest(cmdType.AuthorizedIDsList, {
      ids: authorizedIds
    });
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AuthorizedIDsList,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    let ids = [];
    for (let i = 0; i < idCount; i++) {
      let val = response.ids?.[`p${i}`] ? response.ids?.[`p${i}`] : '';
      ids.push(val);
    }
    setAuthorizedIds(ids);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.AuthorizedIDsList) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Authorized IDs list"
      description="List of authorized iButton/RFID IDs. iButton/RFID number must be entered full into Authorized IDs list (16 symbols in hexadecimal format). If iButton/RFID will be entered shorter that 16 symbols it will not be saved into device."
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="position-relative d-flex flex-column align-items-center py-3 ps-5 overflow-auto" style={{ height: "40rem", paddingRight: "12rem" }}>
          {authorizedIds?.map((id, index) => (
            <TextInput
              key={index}
              placeholder={`ID ${index + 1}`}
              type="text"
              value={id}
              onChange={(value) => {
                let newValues = [...authorizedIds];
                newValues[index] = value;
                setAuthorizedIds(newValues);
              }} />
          ))}

          <img className="position-absolute top-0 end-0 me-3 mt-3" src="/assets/ibutton.svg" />
        </div>
      )} />
  );
};

export default PanelAuthorizedIDList;
