import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import SensorByMac from "./eyesensor/SensorByMac";
import SensorByName from "./eyesensor/SensorByName";
import { sendCommandMsg } from "../../../socket";


const namesCount = 25;
const initialNames = [];
for (let i = 0; i < namesCount; i++) {
  initialNames.push("");
}


const PanelEyeSensor = ({ token, deviceImei, commandResponse }) => {

  const [enabled, setEnabled] = useState("");

  const [nameFilter, setNameFilter] = useState(false);
  const [macFilter, setMacFilter] = useState(false);

  const [sensorByName, setSensorByName] = useState(null);

  const [sensorByMac1, setSensorByMac1] = useState(null);
  const [sensorByMac2, setSensorByMac2] = useState(null);
  const [sensorByMac3, setSensorByMac3] = useState(null);
  const [sensorByMac4, setSensorByMac4] = useState(null);

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAction = async () => {
    return sendCommonRequest(cmdType.EYESensor, {
      enabled,
      filter: (nameFilter && 1) | (macFilter && 2),
      sensorByName,
      sensorByMacs: [
        sensorByMac1, sensorByMac2, sensorByMac3, sensorByMac4
      ],
    });
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.EYESensor,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setEnabled(response.enabled);
    setNameFilter(response.filter & 1);
    setMacFilter(response.filter & 2);
    setSensorByName(response.sensorByName);
    setSensorByMac1(response.sensorByMacs?.[1]);
    setSensorByMac2(response.sensorByMacs?.[2]);
    setSensorByMac3(response.sensorByMacs?.[3]);
    setSensorByMac4(response.sensorByMacs?.[4]);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.EYESensor) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="EYE Sensor"
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5 panel-eye-sensor">
          <SelectBox
            name="EYE Sensor Detection"
            value={enabled}
            onChange={(e) => setEnabled(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disabled" },
              { value: "2", label: "Enabled" },
            ]}
          />
          {enabled == 2 &&
            <>
              <p className="input-element border-0 mb-1" style={{ height: "2rem" }}>EYE Sensor Filter</p>
              <div className="input-element border-0 position-relative d-flex justify-content-start align-items-center" style={{ paddingLeft: "8rem" }}>
                <Switch
                  value={nameFilter}
                  onChange={(checked) => setNameFilter(checked)}
                  suffix={"Name"}
                />
              </div>
              <div className="input-element border-0 position-relative d-flex justify-content-start align-items-center mb-3" style={{ paddingLeft: "8rem" }}>
                <Switch
                  value={macFilter}
                  onChange={(checked) => setMacFilter(checked)}
                  suffix={"MAC"}
                />
              </div>

              {nameFilter &&
                <SensorByName values={sensorByName} onChange={(values) => setSensorByName(values)} />
              }

              {macFilter &&
                <div className="w-100">
                  <SensorByMac num={1} values={sensorByMac1} onChange={(values) => setSensorByMac1(values)} />
                  <SensorByMac num={2} values={sensorByMac2} onChange={(values) => setSensorByMac2(values)} />
                  <SensorByMac num={3} values={sensorByMac3} onChange={(values) => setSensorByMac3(values)} />
                  <SensorByMac num={4} values={sensorByMac4} onChange={(values) => setSensorByMac4(values)} />
                </div>
              }
            </>
          }
        </div>
      )} />
  );
};

export default PanelEyeSensor;
