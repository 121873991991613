import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelGPRSSetup = ({token, deviceImei, commandResponse}) => {
  
  const [apnCheck, setApnCheck] = useState(false);
  const [apnName, setApnName] = useState("");
  const [apnUserName, setApnUserName] = useState("");
  const [apnPassword, setApnPassword] = useState("");
  const [limitConn, setLimitConn] = useState("");
  const [gprsAuth, setGprsAuth] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")

  const sendGPRSSetup = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GprsSetup,
      params: {
        enable: apnCheck ? "1" : "0",
        apnName: apnName,
        apnUName: apnUserName,
        apnPwd: apnPassword,
        limitConn: limitConn,
        gprsAuth: gprsAuth,
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GprsSetup,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setApnCheck(parseInt(response.enable) === 1);
    setApnName(response.apnName);
    setApnUserName(response.apnUName);
    setApnPassword(response.apnPwd);
    setLimitConn(parseInt(response.limitConn) === 1);
    setGprsAuth(response.gprsAuth);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.GprsSetup) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="GPRS Setup"
      description="Setup sending data by GPRS link. Note: after changing this setting device will be disconnected from current server."
      date={updatedDate}
      onSendAction={sendGPRSSetup}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={apnCheck}
              onChange={(checked) => setApnCheck(checked)}
              suffix={"GPRS Enabled"}
              />
          </div>
          <TextInput
            name="APN Name"
            tooltip=""
            type="text"
            value={apnName}
            onChange={(value) => setApnName(value)} />
          <TextInput
            name="APN Username"
            tooltip=""
            type="text"
            value={apnUserName}
            onChange={(value) => setApnUserName(value)} />
          <TextInput
            name="APN Password"
            tooltip=""
            type="text"
            value={apnPassword}
            onChange={(value) => setApnPassword(value)} />
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={limitConn}
              onChange={(checked) => setLimitConn(checked)}
              suffix={"Limit connection errors"}
              />
          </div>
          <SelectBox 
            name="GPRS Authentication"
            value={gprsAuth}
            onChange={(e) => setGprsAuth(e.target.value)}
            options={[ 
              { value: "", label: "" },
              { value: "0", label: "None" },
              { value: "1", label: "PAP" },
              { value: "2", label: "CHAP" },
            ]}
            />
        </div>
      )} />
  );
};

export default PanelGPRSSetup;
