import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { CSVLink } from "react-csv";
import { faMicrochip, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { removeDevice } from "../../services/axios";
import { DeviceModels } from "../../utils/mockup";

import "./Devices.css";
import { formatDateTime, showConfirmEx } from "../../utils/globals";
import { Select, notification } from "antd";
import { getDevices } from "../../redux/actions/devices";
import { DeviceType } from "../../common/common";

const Devices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const devices = useSelector((state) => state.devicesList.devices);

  const [stateColor, setStateColor] = useState("#7A7D8B");
  const [searchPlateText, setSearchPlateText] = useState("");
  const [searchImeiText, setSearchImeiText] = useState("");
  const [searchDeviceText, setSearchDeviceText] = useState("");
  const [searchStatusText, setSearchStatusText] = useState("");
  const [searchModelText, setSearchModelText] = useState(null);
  const [devicesData, setDevicesData] = useState(devices);
  const [searchFold, setSearchFold] = useState(true);
  
  useEffect(() => {
    (async () => {
      dispatch(getDevices(false));
    })();
  }, []);

  useEffect(() => {
    setDevicesData(
      devices?.filter((item) => {
        return (
          (searchPlateText == "" || item.vehicleName.toLocaleLowerCase().includes(searchPlateText.toLocaleLowerCase())) &&
          (searchImeiText == "" || item.deviceImei.toLocaleLowerCase().includes(searchImeiText.toLocaleLowerCase())) &&
          (searchDeviceText == "" || item.deviceType === searchDeviceText) &&
          (searchStatusText == "" || (searchStatusText == "Connected" && (item.status === "Connected" || item.status === "Idle")) || (searchStatusText == "Not Connected" && item.status == searchStatusText)) &&
          (searchModelText == "" || searchModelText == null || item.deviceModel === searchModelText)
        );
      })
    );
  }, [
    searchPlateText,
    searchImeiText,
    searchDeviceText,
    searchStatusText,
    searchModelText,
    devices,
  ]);

  const handlePlate = (event) => {
    const val = event.target.value;
    setSearchPlateText(val);
  };

  const handleImei = (event) => {
    const val = event.target.value;
    setSearchImeiText(val);
  };

  const handleDevice = (event) => {
    const val = event.target.value;
    setSearchDeviceText(val);
  };

  const handleModel = (value) => {
    setSearchModelText(value);
  };

  const handleStateColor = (value) => {
    const val = value.target.value;
    setSearchStatusText(val);
    if (val === "Connected") {
      setStateColor("#63D16E");
    } else if (val === "Not Connected") {
      setStateColor("#FF3062");
    } else {
      setStateColor("#7A7D8B");
    }
  };

  const handleClear = () => {
    setSearchPlateText("");
    setSearchImeiText("");
    setSearchDeviceText("");
    setSearchModelText("");
    setSearchStatusText("");
    setStateColor("grey");
  };

  const handleViewDevice = (
    vehicleType,
    plate,
    imei,
    device,
    model,
    camera,
    sensors,
    mobile,
    user
  ) => {
    navigate("/Devices/ViewDevice", {
      state: {
        vehicleType: vehicleType,
        plate: plate,
        imei: imei,
        device: device,
        model: model,
        camera: camera,
        sensors: sensors,
        mobile: mobile,
        user: user,
      },
    });
  };

  const handleEditDevice = (
    vehicleType,
    plate,
    imei,
    device,
    model,
    camera,
    sensors,
    mobile,
    user
  ) => {
    navigate("/Devices/EditDevice", {
      state: {
        vehicleType: vehicleType,
        plate: plate,
        imei: imei,
        device: device,
        model: model,
        camera: camera,
        sensors: sensors,
        mobile: mobile,
        user: user,
      },
    });
  };

  const handleLocationClick = (data) => {
    /*if (data?.lng && data?.lat) {
      navigate("/Dashboard", { state: { lng: data.lng, lat: data.lat } });
    }*/
    let url = `http://www.google.com/maps/place/${data?.lat},${data?.lng}`;
    window.open(url, "_blank");
  };

  const handleRemoveDevice = async (imei, name) => {
    showConfirmEx(`Are you sure to remove vehicle No. ${name}?`)
      .then(async () => {
        const removeDeviceData = {
          token: localStorage.getItem("token"),
          deviceImei: imei,
        };
        const res = await removeDevice(removeDeviceData);
        if (res?.status === 200) {
          notification.success({
            description: "Device has been removed successfully!",
          });
          dispatch(getDevices(false));
        }
      })
      .catch (() => {
      });
  };

  return (
    <div className="devices-main">
      {!isMobile ? (
        <div className="devices-div1">
          <div className="sub1-div1">
            <p className="px-5">
              Total Devices<span className="ms-3">{devicesData?.length}</span>
            </p>
            <div className="subsub1-sub1-div1">
              <CSVLink
                data={devicesData}
                filename="Devices"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/export.svg" alt="none" />
                  <button className="ms-2">Export</button>
                </div>
              </CSVLink>
              <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                <img
                  src="/assets/addbtn.svg"
                  alt="none"
                  onClick={() => navigate("/Devices/AddDevice")}
                />
                <button onClick={() => navigate("/Devices/AddDevice")}>
                  Add Device
                </button>
              </div>
            </div>
          </div>
          <div className="sub2-div1">
            <div className="subsub1-sub2-div1 py-2">
              <div className="sub1-subsub1-sub2-div1 my-2">
                <img
                  src="/assets/Searchwithperson.svg"
                  alt="none"
                  className="search-icon-content"
                />
                <span>Search device</span>
              </div>
              <div className="sub2-subsub1-sub2-div1">
                <input
                  className="field-input"
                  type="text"
                  placeholder="Plate No."
                  value={searchPlateText}
                  onChange={handlePlate}
                />
                <input
                  className="field-input"
                  type="numder"
                  placeholder="IMEI"
                  value={searchImeiText}
                  onChange={handleImei}
                />
                <select
                  className="field-select"
                  value={searchDeviceText}
                  onChange={handleDevice}
                >
                  <option key={0} value="">Device</option>
                  <option key={1} value={DeviceType.Teltonika}>{DeviceType.Teltonika}</option>
                  <option key={2} value={DeviceType.Ruptela}>{DeviceType.Ruptela}</option>
                </select>
                <Select
                  showSearch
                  allowClear
                  className="field-select"
                  placeholder="Model"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={DeviceModels.filter(item => (searchDeviceText == "" || item.type == searchDeviceText)).map((item) => ({
                    label: item.device, value: item.device
                  }))} 
                  value={searchModelText}
                  onChange={(value) => handleModel(value)}
                />
                <select
                  className="field-select"
                  style={{ color: stateColor }}
                  onChange={handleStateColor}
                  value={searchStatusText}
                >
                  <option value="">Status</option>
                  <option style={{ color: "#63D16E" }} value="Connected">Connected</option>
                  <option style={{ color: "#FF3062" }} value="Not Connected">Not Connected</option>
                </select>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-devices-div2 py-3">
                <p className="mb-0 ps-5">Plate No.</p>
                <p className="mb-0">Device</p>
                <p className="mb-0">Model</p>
                <p className="mb-0">IMEI No.</p>
                <p className="mb-0">Last Location</p>
                <p className="mb-0">Company</p>
                <p className="mb-0">Status</p>
                <p className="mb-0"></p>
              </div>
              <div className="sub2-div2 overflow-auto">
                {devicesData?.map((item, index) => {
                  return (
                    <div id="import-devices" key={index}>
                      <p id="sub1-import-devices">{index + 1}</p>
                      <div className="subsub1-sub2-devices-div2">
                        <p className="item ps-5">{item?.vehicleName}</p>
                        <p className="item">{item?.deviceType}</p>
                        <p className="item">{item?.deviceModel}</p>
                        <p className="item">{item?.deviceImei}</p>
                        <div className="import-gps-time-devices item">
                          <p className="text-center">
                            &nbsp;GPS:{" "}
                            <span
                              className="cursor-pointer"
                              onClick={() => handleLocationClick(item)}
                              style={{ color: "rgb(133 29 209)" }}
                            >
                              {item.lat + ", " + item.lng}
                            </span>
                          </p>
                          <p className="text-center">
                            Time:{" "}
                            <span className="ms-1">
                              {formatDateTime(item?.gpsFixedDate)}
                            </span>
                          </p>
                        </div>
                        <p className="item">{item?.company?.name}</p>
                        <p
                          className="item"
                          style={{color: item?.status == "Not Connected" ? "#FF3062" : "#63D16E"}}
                        >
                          {item?.status == "Idle" ? "Connected" : item?.status}
                        </p>
                        <div className="devices-dropdown item d-flex position-relative">
                          <FontAwesomeIcon 
                            className="dropdown-toggle devices-dropdown-img px-3" 
                            icon={faEllipsisVertical} 
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu devices-dropdown-div">
                            <div className="sub1--devices-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() =>
                                  handleViewDevice(
                                    item?.vehicleType,
                                    item?.vehicleName,
                                    item?.deviceImei,
                                    item?.deviceType,
                                    item?.deviceModel,
                                    item?.camera,
                                    item?.sensors,
                                    item?.mobileNo,
                                    item?.user
                                  )
                                }
                              >
                                <img
                                  src="/assets/view.svg"
                                  alt="none"
                                  style={{ width: "1.5rem", height: "2rem" }}
                                />
                                <p className="mb-0 ms-3">View</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() =>
                                  {console.log(item?.vehicle);
                                  handleEditDevice(
                                    item?.vehicleType,
                                    item?.vehicleName,
                                    item?.deviceImei,
                                    item?.deviceType,
                                    item?.deviceModel,
                                    item?.camera,
                                    item?.sensors,
                                    item?.mobileNo,
                                    item?.user
                                  )}
                                }
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() =>
                                  handleRemoveDevice(item?.deviceImei, item?.vehicleName)
                                }
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Remove</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{
                color: "white",
                backgroundColor: "#1A2678",
                borderRadius: "30px",
              }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon className="me-4" icon={faMicrochip} />
                Devices
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0">
              Total Devices <span className="ms-3">{devicesData?.length}</span>
            </p>
            <div className="d-flex">
              <CSVLink
                data={devicesData}
                filename="Devices"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/export.svg" alt="none" />
                  <button>Export</button>
                </div>
              </CSVLink>
              <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                <img
                  src="/assets/addbtn.svg"
                  alt="none"
                  onClick={() => navigate("/Devices/AddDevice")}
                />
                <button onClick={() => navigate("/Devices/AddDevice")}>
                  Add Device
                </button>
              </div>
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2">
              <img
                src="/assets/Searchwithperson.svg"
                alt="none"
                className="search-icon-content"
              />
              <span>Search device</span>
              <img
                className="ms-auto accordion"
                style={
                  !searchFold
                    ? { transform: "rotate(180deg)" }
                    : { transform: "none" }
                }
                src="/assets/arrow-down.png"
                alt="Arrow Down"
                onClick={() => setSearchFold(!searchFold)}
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="text"
                      placeholder="Plate No."
                      value={searchPlateText}
                      onChange={handlePlate}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="numder"
                      placeholder="IMEI"
                      value={searchImeiText}
                      onChange={handleImei}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <select
                      className="field-select"
                      value={searchDeviceText}
                      onChange={handleDevice}
                    >
                      <option key={0} value="">Device</option>
                      <option key={1} value={DeviceType.Teltonika}>{DeviceType.Teltonika}</option>
                      <option key={2} value={DeviceType.Ruptela}>{DeviceType.Ruptela}</option>
                    </select>
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      showSearch
                      allowClear
                      className="field-select"
                      placeholder="Select Model"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={DeviceModels.filter(item => (searchDeviceText == "" || item.type == searchDeviceText)).map((item) => ({
                        label: item.device, value: item.device
                      }))} 
                      value={searchModelText}
                      onChange={(value) => handleModel(value)}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <select
                      className="field-select"
                      style={{ color: stateColor }}
                      onChange={handleStateColor}
                      value={searchStatusText}
                    >
                      <option value="">Status</option>
                      <option style={{ color: "#63D16E" }} value="Connected">Connected</option>
                      <option style={{ color: "#FF3062" }} value="Not Connected">Not Connected</option>
                    </select>
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div
            className={`sub2-div2 d-flex flex-column px-4 w-100 overflow-auto ms-0 ${
              searchFold && "folded"
            }`}
          >
            {devicesData?.map((item, index) => {
              return (
                <div id="import-devices" key={index}>
                  <p id="sub1-import-devices">{index + 1}</p>
                  <div className="subsub1-sub2-devices-div2 d-flex flex-column align-items-center py-2 px-3">
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Plate No.</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.vehicleName}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Device</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceType}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Model</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceModel}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">IMEI</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceImei}
                      </p>
                    </div>
                    <div className="d-flex flex-row align-items-start w-100">
                      <p className="mb-0 px-2 col-4 text-white">
                        Last Location
                      </p>
                      <div className="import-gps-time-devices mb-0 px-2 col-8 item justify-content-start flex-column">
                        <p>
                          GPS:
                          <span
                            className="cursor-pointer"
                            onClick={() => handleLocationClick(item) }
                            style={{ color: "rgb(133 29 209)" }}
                          >
                            {item.lat + ", " + item.lng}
                          </span>
                        </p>
                        <p>
                          Time:{" "}
                          <span className="ms-1">
                            {formatDateTime(item?.gpsFixedDate)}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Status</p>
                      <div className="mb-0 px-2 col-8 item justify-content-between">
                        <p
                          className="item mb-0 justify-content-start"
                          style={{color: item?.status == "Not Connected" ? "#FF3062" : "#63D16E"}}
                        >
                          {item?.status == "Idle" ? "Connected" : item?.status}
                        </p>
                        <div className="devices-dropdown d-flex position-relative">
                          <FontAwesomeIcon 
                            className="dropdown-toggle devices-dropdown-img px-3" 
                            icon={faEllipsisVertical} 
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu devices-dropdown-div">
                            <div className="sub1--devices-dropdown-div mx-4 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() =>
                                  handleViewDevice(
                                    item?.vehicleType,
                                    item?.vehicleName,
                                    item?.deviceImei,
                                    item?.deviceType,
                                    item?.deviceModel,
                                    item?.camera,
                                    item?.sensors,
                                    item?.mobileNo,
                                    item?.user
                                  )
                                }
                              >
                                <img
                                  src="/assets/view.svg"
                                  alt="none"
                                  style={{ width: "1.5rem", height: "2rem" }}
                                />
                                <p className="mb-0 ms-3">View</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() =>
                                  handleEditDevice(
                                    item?.vehicleType,
                                    item?.vehicleName,
                                    item?.deviceImei,
                                    item?.deviceType,
                                    item?.deviceModel,
                                    item?.camera,
                                    item?.sensors,
                                    item?.mobileNo,
                                    item?.user
                                  )
                                }
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() =>
                                  handleRemoveDevice(item?.deviceImei, item?.vehicleName)
                                }
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Remove</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Devices;
