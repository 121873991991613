import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const initialUids = [];
for (let i = 0; i < 25; i++) {
  initialUids.push("");
}
const PanelBeaconsList = ({token, deviceImei, commandResponse}) => {
  
  const [bleBeaconUids, setBleBeaconUids] = useState(initialUids);

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAuthorizedBeaconslist = async () => {
    let params = {};
    for (let i = 0; i < 25; i++) {
      params[`p${i+1}`] = bleBeaconUids[i];
    }
    return sendCommonRequest(cmdType.AuthorizedBeaconsList, params);
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AuthorizedBeaconsList,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setBleBeaconUids([
      response.b1, response.b2, response.b3, response.b4, response.b5, 
      response.b6, response.b7, response.b8, response.b9, response.b10, 
      response.b11, response.b12, response.b13, response.b14, response.b15, 
      response.b16, response.b17, response.b18, response.b19, response.b20, 
      response.b21, response.b22, response.b23, response.b24, response.b25 
    ]);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.AuthorizedBeaconsList) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Authorized beacons list"
      description="The list of Authorized BLE Beacons. iBeacon format supported (e.g. 00026da64fa24e988024bc5b71e0893e-1111-CCCC) and Eddystone (e.g. 00026da64fa24e988024bc5b-111111111111)."
      date={updatedDate}
      onSendAction={sendAuthorizedBeaconslist}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          {bleBeaconUids.map((bleBeaconUid, index) => (
            <TextInput
              key={index}
              placeholder={`BLE Beacon UID ${index+1}`}
              type="text"
              value={bleBeaconUid}
              options={{ style: { textAlign: "center", padding: "6px" } }}
              onChange={(value) => {
                let newValues = [...bleBeaconUids];
                newValues[index] = value;
                setBleBeaconUids(newValues);
              }} />
          ))}
        </div>
      )} />
  );
};

export default PanelBeaconsList;
