import "./Switch.css";

const Switch2 = ({ name, prefix="Disable", suffix="Enable", value, onChange }) => {
    return (
        <div className="btn-group" role="group">
            <input type="radio" className="btn-check" name={name} id={`rd1_${name}`} checked={value == 0} onClick={() => onChange(0)} onChange={(val) => {}} />
            <label className="btn" htmlFor={`rd1_${name}`}>{prefix}</label>
            <input type="radio" className="btn-check" name={name} id={`rd2_${name}`} checked={value == 1} onClick={() => onChange(1)} onChange={(val) => {}} />
            <label className="btn" htmlFor={`rd2_${name}`}>{suffix}</label>
        </div>
    );
}

export default Switch2;