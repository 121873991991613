import React, { useEffect, useState } from "react";
import { CommandDoutControls, CommandPriorities, cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import Switch from "../../commons/switch/Switch";
import TextInput from "../../commons/input/TextInput";
import { sendCommandMsg } from "../../../socket";

const PanelNetworkJamming = ({token, deviceImei, commandResponse}) => {
  
  const [priority, setPriority] = useState("");
  const [eventOnly, setEventOnly] = useState(false);
  const [outputControl, setOutputControl] = useState("");
  const [onDuration, setOnDuration] = useState("");
  const [offDuration, setOffDuration] = useState("");
  const [timeUntilJamming, setTimeUntilJamming] = useState("");

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendJaming = async () => {
    return sendCommonRequest(cmdType.NetworkJamming, {
      priority: priority,
      eventOnly: eventOnly ? "1" : "0",
      outputControl: outputControl,
      onDuration: onDuration,
      offDuration: offDuration,
      timeUntilJamming: timeUntilJamming,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.NetworkJamming,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }
  
  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setPriority(response.priority);
    setEventOnly(parseInt(response.eventOnly) === 1);
    setOutputControl(response.outputControl);
    setOnDuration(response.onDuration);
    setOffDuration(response.offDuration);
    setTimeUntilJamming(response.timeUntilJamming);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.NetworkJamming) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);


  return (
    <CommandSettingPanel
      title="Network Jamming"
      description="Jamming"
      date={updatedDate}
      onSendAction={sendJaming}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Scenario Priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            options={CommandPriorities}
          />
          <div className="input-element border-0 position-relative d-flex justify-content-start mb-3">
            <Switch
              value={eventOnly}
              onChange={(checked) => setEventOnly(checked)}
              suffix={"Event Only"}
            />
          </div>
          <SelectBox
            name="Output Control"
            value={outputControl}
            onChange={(e) => setOutputControl(e.target.value)}
            options={CommandDoutControls}
          />
          <TextInput
            name="On Duration"
            type="number"
            suffix="ms"
            value={onDuration}
            options={{ min: 100, max: 5000 }}
            onChange={(value) => setOnDuration(value)} />
          <TextInput
            name="Off Duration"
            type="number"
            suffix="ms"
            value={offDuration}
            options={{ min: 0, max: 5000 }}
            onChange={(value) => setOffDuration(value)} />
          <TextInput
            name="Time Until Jamming Event Detection"
            type="number"
            suffix="s"
            value={timeUntilJamming}
            options={{ min: 0, max: 65535 }}
            onChange={(value) => setTimeUntilJamming(value)} />
        </div>
      )} />
  );
};

export default PanelNetworkJamming;
