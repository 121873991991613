import React, { useEffect, useState } from "react";
import "./CommandsQueue.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettingsById, showComandQueue } from "../../../services/axios";
import { formatDateTimeSec, sleep, truncate } from "../../../utils/globals";
import { Input, Tooltip, message } from "antd";
import { useMediaQuery } from "@mui/material";
import { sendCommandMsg, socket } from "../../../socket";

const CommandsQueue = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const token = useSelector((state) => state.auth.token);
  const getData = useLocation();
  const deviceImei = getData?.state?.deviceImei;

  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowSendCommand, setIsShowSendCommand] = useState(false);
  const [command, setCommand] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(1);

  const [dataList, setDataList] = useState([]);

  const [sendingCommandId, setSendingCommandId] = useState(null);

  const handleSendCommand = async () => {
    if (command.trim() === "") {
      return;
    }
    setIsShowSendCommand(false);
    setIsLoading(true);
    const sendCommandData = {
      token: token,
      devImei: deviceImei,
      type: cmdType.Custom,
      command: command,
    }
    const res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      setSendingCommandId(res.data.commandId);
      sendCommandMsg(res.data.commandId);
    }
    setCommand("");

    await sleep(10000);
    await getCommands(1);
    setIsLoading(false);
  }

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (sendingCommandId == cmdData.commandId) {
        setIsLoading(false);
      }
      if (cmdData.deviceImei == deviceImei) {
        await getCommands(1);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);


  const getCommands = async (page = 1) => {
    setIsLoading(true);
    const sendData = {
      token: token,
      devImei: deviceImei,
      pageNum: page,
      pageSize: pageSize
    }
    var res = await showComandQueue(sendData);
    if (page === 1) {
      setDataList(res?.data?.result);
    } else {
      setDataList([
        ...dataList,
        ...res?.data?.result
      ]);
    }
    setTotalCount(res?.data?.totalCount);
    setPageNum(page);
    setIsLoading(false);
  }

  useEffect(() => {
    getCommands(1);
  }, [token, deviceImei]);

  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: 'Action in progress..',
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading]);


  const scrollRef = useBottomScrollListener(async () => {
    const totalPage = Math.ceil(totalCount / pageSize);
    if (totalPage > pageNum) {
      await getCommands(pageNum + 1);
    }
  });

  return (
    <div className="commands-queue-div1" style={isMobile ? { height: "calc(100vh - 200px)" } : {}}>
      {contextHolder}
      <div className="sub1-commands-queue-div1">
        <p onClick={() => setIsShowSendCommand(true)}>
          <img src="/assets/Wadd.svg" alt="none" />
          New Command
        </p>
      </div>
      <div className="commands-queue-div2">
        {isMobile ?
          <>
            <div className="sub2-commands-queue-div2 scrollbar-hide" ref={scrollRef}>
              {dataList?.map((item, index) => {
                return (
                  <div className="subsub1-sub2-company-div2 d-flex flex-column align-items-center py-2 px-3" key={index}>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Command</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        <Tooltip title={item.command} trigger="click">
                          {truncate(item.command, 40)}
                        </Tooltip>
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Description</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item.cmdType != cmdType.EngineOn ? item.cmdType : (
                          item.command.startsWith("setdigout 1") ? "Engine ON" : "Engine OFF"
                        )}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Username</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item.sendByUsername}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Time</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {formatDateTimeSec(item.updatedAt)}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Status</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start" style={{ color: item.status === "Sent" ? "blue" : item.status === "Received" ? "green" : item.status === "Queued" ? "gray" : "red" }}>
                        {item.status == "Received" ? "Success" : item.status}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Response</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        <Tooltip title={item.response} trigger="click">
                          <p>{truncate(item.response, 40)}</p>
                        </Tooltip>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
          : <>
            <div className="sub1-commands-queue-div2">
              <div className="subsub1-sub1-commands-queue-div2">
                <p className="ps-5"><span>Command</span><img src="/assets/upRed.svg" alt="none" className="ms-3" /></p>
                <p>Description</p>
                <p>Username</p>
                <p>Time</p>
                <p>Status</p>
                <p><span>Response</span><img src="/assets/downGreen.svg" alt="none" className="ms-3" /></p>
              </div>
            </div>
            <div className="sub2-commands-queue-div2" ref={scrollRef}>
              {dataList?.map((item, index) => {
                return (
                  <div className="subsub1-sub2-commands-queue-div2" key={index}>
                    <Tooltip title={item.command} trigger="click">
                      <p className="ps-5">{truncate(item.command, 36)}</p>
                    </Tooltip>
                    <p>{item.cmdType}</p>
                    <p>{item.sendByUsername}</p>
                    <p>{formatDateTimeSec(item.updatedAt)}</p>
                    <p style={{ color: item.status === "Sent" ? "blue" : item.status === "Received" ? "green" : item.status === "Queued" ? "gray" : "red" }}>{item.status == "Received" ? "Success" : item.status}</p>
                    <Tooltip title={item.response} trigger="click">
                      <p>{truncate(item.response, 40)}</p>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </>
        }
      </div>
      {/*  */}
      <div className="commands-queue-portal-wrapper" style={{ display: (isShowSendCommand ? "block" : "none") }}>
        <div className="position-relative">
          <img src="/assets/close.svg" alt="none" className="commands-queue-portal-close"
            onClick={() => setIsShowSendCommand(false)}
          />
          <div className="commands-queue-portal">
            <label>Type Command</label>
            <Input type="text" value={command} onChange={(e) => setCommand(e.target.value)} />
            <img className="ms-5" src="/assets/send.svg" alt="none" onClick={() => handleSendCommand()} />
          </div>
        </div>
      </div>

      {/*  */}
    </div>
  );
};

export default CommandsQueue;
