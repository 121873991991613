import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelBatteryChargeMode = ({token, deviceImei, commandResponse}) => {
  
  const [batteryChargeMode, setBatteryChargeMode] = useState("")
  const [updatedDate, setUpdatedDate] = useState("")

  const sendBatteryCharge = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.BatteryChargeMode,
      params: {
        mode: batteryChargeMode
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.BatteryChargeMode,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setBatteryChargeMode(response.mode);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.BatteryChargeMode) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Battery charge mode"
      description=""
      date={updatedDate}
      onSendAction={sendBatteryCharge}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Mode"
            value={batteryChargeMode}
            onChange={(e) => setBatteryChargeMode(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "2", label: "Always" },
              { value: "0", label: "On Need" },
              { value: "1", label: "After Ignition ON" },
            ]}
          />
        </div>
      )} />
  );
};

export default PanelBatteryChargeMode;
