import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings, getCommandSettingsById } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg, socket } from "../../../socket";


const Actions = () => {
  
  const token = useSelector((state) => state.auth.token);

  const [requestGpsDate, setRequestGpsDate] = useState(null);
  const [formatSdCardDate, setFormatSdCardDate] = useState(null);
  const [restartDevDate, setRestartDevDate] = useState(null);
  const [connectWebDate, setConnectWebDate] = useState(null);
  const [genReportDate, setGenReportDate] = useState(null);
  const [getIccidDate, setGetIccidDate] = useState(null);
  
  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
    deviceIccid: getData?.state?.deviceIccid,
  }
  

  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: data?.deviceImei
    }
    var res = await commandSetting(sendCommandData);
    return res;
  }

  const sendRequestGps = async () => {
    const res = await sendCommonRequest(cmdType.ReqGPSDataTime, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setRequestGpsDate(formatDateTime(new Date()));
    }
  }

  const sendFormatSdCard = async () => {
    const res = await sendCommonRequest(cmdType.FormatSDcard, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setFormatSdCardDate(formatDateTime(new Date()));
    }
  }

  const sendRestartDev = async () => {
    const res = await sendCommonRequest(cmdType.RestartDev, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setRestartDevDate(formatDateTime(new Date()));
    }
  }

  const sendConnectToFotaWeb = async () => {
    const res = await sendCommonRequest(cmdType.ConnectToFotaWeb, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setConnectWebDate(formatDateTime(new Date()));
    }
  }

  const sendForceDev = async () => {
    const res = await sendCommonRequest(cmdType.ForceDevToGenReport, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setGenReportDate(formatDateTime(new Date()));
    }
  }

  const sendGetTimeIccid = async () => {
    const res = await sendCommonRequest(cmdType.GetimeiccidCmd, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setGetIccidDate(formatDateTime(new Date()));
    }
  }


  useEffect(async () => {
    let sendCommandData = {
      token: token,
      type: cmdType.ReqGPSDataTime,
      devImei: data?.deviceImei,
      isLast: true,
    }
    
    let res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setRequestGpsDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.FormatSDcard;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setFormatSdCardDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.RestartDev;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setRestartDevDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.ConnectToFotaWeb;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setConnectWebDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.ForceDevToGenReport;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setGenReportDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.GetimeiccidCmd;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setGetIccidDate(formatDateTime(res.data.updatedAt));
    }

  }, []);


  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }

      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.ReqGPSDataTime) {
      setRequestGpsDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.FormatSDcard) {
      setFormatSdCardDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.RestartDev) {
      setRestartDevDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.ConnectToFotaWeb) {
      setConnectWebDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.ForceDevToGenReport) {
      setGenReportDate(formatDateTime(commandResponse.updatedAt));
    }
    else if (commandResponse?.cmdType == cmdType.GetimeiccidCmd) {
      setGetIccidDate(formatDateTime(commandResponse.updatedAt));
    }
  }, [commandResponse]);


  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <CommandSettingPanel
          title="Request current GPS data, date and time."
          description="Send getgps command to the device. The response will be stored as telemetry message"
          onSendAction={sendRequestGps}
          date={requestGpsDate} />
        <CommandSettingPanel
          title="Format SD card"
          description="Send sdformat command to the device. It will clear all undelivered telemetry reports"
          date={formatSdCardDate} 
          onSendAction={sendFormatSdCard} />
      </div>

      <div className="col-md-4">
        <CommandSettingPanel
          title="Restart (reboot) the device"
          date={restartDevDate} 
          onSendAction={sendRestartDev} />
        <CommandSettingPanel
          title="Connect to Fota WEB immediately."
          date={connectWebDate} 
          onSendAction={sendConnectToFotaWeb} />
      </div>

      <div className="col-md-4">
        <CommandSettingPanel
          title="Force device to generate a report"
          date={genReportDate} 
          onSendAction={sendForceDev} />
        <CommandSettingPanel
          title="Send getimeiccid command"
          description={<>IMEI: {data?.deviceImei}<br/> ICCID: {data?.deviceIccid}</>}
          date={getIccidDate} 
          onSendAction={sendGetTimeIccid} />
      </div>
    </div>
  );
};

export default Actions;
