import React, { useEffect, useState } from "react";
import { CommandDoutControls, cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelDoutIgnitionControl = ({token, deviceImei, commandResponse}) => {
  
  const [dout_control, setDoutControl] = useState("")
  const [dout_deactivate, setDoutDeactivate] = useState("")
  const [dout_ignition, setDoutIgnition] = useState("")
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendDoutControlViaIgnition = async () => {
    return sendCommonRequest(cmdType.DoutControlViaIgnition, {
      dctl: dout_control,
      deact: dout_deactivate,
      tout: dout_ignition
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.DoutControlViaIgnition,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setDoutControl(response.dctl);
    setDoutDeactivate(response.deact);
    setDoutIgnition(response.tout);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.DoutControlViaIgnition) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Dout control via ignition"
      description="Once ignition status changes from ON to OFF and set timeout passes then selected DOUT is turned ON. Turning DOUT OFF is possible with a signal (voltage) applied to a configured DIN."
      date={updatedDate}
      onSendAction={sendDoutControlViaIgnition}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Dout Control"
            value={dout_control}
            onChange={(e) => setDoutControl(e.target.value)}
            options={CommandDoutControls}
          />
          <SelectBox
            name="Dout Deactivation via DIN"
            value={dout_deactivate}
            onChange={(e) => setDoutDeactivate(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "None" },
              { value: "1", label: "DIN1" },
              { value: "2", label: "DIN2" },
              { value: "3", label: "DIN3" },
            ]}
          />
          <TextInput
            name="Ignition OFF Timeout"
            type="number"
            suffix="seconds"
            value={dout_ignition}
            onChange={(value) => setDoutIgnition(value)} />
        </div>
      )} />
  );
};

export default PanelDoutIgnitionControl;
