import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelGNSSSource = ({token, deviceImei, commandResponse}) => {
  
  const [beidou, setBeidou] = useState("");
  const [glonass, setGlonass] = useState("");
  const [galileo, setGalileo] = useState("");
  const [gps, setGps] = useState("");

  const [updatedDate, setUpdatedDate] = useState("")
  
  const sendMotionSource = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GnssSource,
      params: {
        opt: (beidou && 1) | (glonass && 2) | (galileo && 4) | (gps && 8)
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GnssSource,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setBeidou(response.opt & 1);
    setGlonass(response.opt & 2);
    setGalileo(response.opt & 4);
    setGps(response.opt & 8);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.GnssSource) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="GNSS Source"
      description=""
      date={updatedDate}
      onSendAction={sendMotionSource}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-start justify-content-center py-3" style={{ paddingLeft: "40%" }}>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={beidou}
              onChange={(checked) => setBeidou(checked)}
              suffix={"Beidou"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={glonass}
              onChange={(checked) => setGlonass(checked)}
              suffix={"Glonass"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={galileo}
              onChange={(checked) => setGalileo(checked)}
              suffix={"Galileo"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={gps}
              onChange={(checked) => setGps(checked)}
              suffix={"GPS"}
              />
          </div>
        </div>
      )} />
  );
};

export default PanelGNSSSource;
