import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelOdometer = ({token, deviceImei, commandResponse}) => {
  
  const [odometer_cal_source, setOdometerCalSource] = useState("GNSS");
  const [odometer_init_val, setOdometerInitVal] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendOdometer = async () => {
    return sendCommonRequest(cmdType.Odometer, {
      calSrc: odometer_cal_source,
      iov: odometer_init_val
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.Odometer,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setOdometerCalSource(response.calSrc);
    setOdometerInitVal(response.iov);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.Odometer) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Odometer"
      description="Set odometer mode and initial value"
      date={updatedDate}
      onSendAction={sendOdometer}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Calculation Source"
            value={odometer_cal_source}
            onChange={(e) => setOdometerCalSource(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "GNSS" },
              { value: "1", label: "OBD" },
              { value: "2", label: "LVCAN" },
            ]}
          />
          <TextInput
            name="Initial Odometer Value"
            suffix="Km/h"
            type="number"
            value={odometer_init_val}
            onChange={(value) => setOdometerInitVal(value)} />
        </div>
      )} />
  );
};

export default PanelOdometer;
