import React, { useEffect, useRef, useState } from "react";
import MapGL, { Layer, Marker, NavigationControl, Source  } from "react-map-gl";
import VehicleMarker from "./VehicleMarker";

import "mapbox-gl/dist/mapbox-gl.css";
import { getMapZoomAndCenter } from "../../../utils/globals";

const MapBoxMap = ({ apiKey, isShowAll, devices, point, selectImei, trackPoints }) => {

  const [selectedImei, setSelectedImei] = useState(null);
  const [trackData, setTrackData] = useState(null);

  const [viewport, setViewport] = useState({
    latitude: point ? point.lat : 24.299615,
    longitude: point ? point.lng : 54.5632233,
    zoom: 16,
    bearing: 0,
    // pitch: 0,
  });

  useEffect(() => {
    if (point) {
      setViewport((prevViewport) => ({
        ...prevViewport,
        zoom: 16,
        latitude: point.lat,
        longitude: point.lng,
      }));
    }
  }, [point]);

  useEffect(() => {
    if (trackPoints && trackPoints.length > 0) {
      let coords = trackPoints.map(item => (
        [item.lng, item.lat]
      ));
      setTrackData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coords
        }
      })
    } else {
      setTrackData(null);
    }

  }, [trackPoints]);

  useEffect(() => {
    if (isShowAll && devices?.length > 0) {
      let result = getMapZoomAndCenter(devices);
      setViewport(prev => ({
        ...prev,
        latitude: result.lat,
        longitude: result.lng,
        zoom: result.zoom,
      }))
    }
  }, [isShowAll])

  useEffect(() => {
    if (selectedImei != null) {
      const device = devices?.find(item => item.deviceImei == selectedImei);
      if (device) {
        setViewport((prevViewport) => ({
          ...prevViewport,
          latitude: device.lat,
          longitude: device.lng,
        }));
        setViewport((prev) => ({
          ...prev,
          bearing: device.angle
        }));

      }
    }
  }, [devices]);

  useEffect(() => {
    if (!selectedImei) {
      setViewport((prev) => ({
        ...prev,
        bearing: 0
      }));
    } else {
      const device = devices?.find(item => item.deviceImei == selectedImei);
      if (device) {
        setViewport((prev) => ({
          ...prev,
          bearing: device.angle
        }));
      }
    }
  }, [selectedImei]);

  const handleSelect = (deviceImei, e) => {
    e.originalEvent.stopPropagation();
    if (deviceImei == selectedImei) {
      setSelectedImei(null);
    } else {
      setSelectedImei(deviceImei);
    }
  }

  useEffect(() => {
    setSelectedImei(selectImei);
  }, [selectImei]);

  return (
    <MapGL
      // bearing={0}
      pitch={0}
      {...viewport}
      style={{ width: "100%", height: "100%" }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken={apiKey}
      onMove={(evt) => setViewport(evt.viewState)}
      onZoom={(evt) => setViewport(prev => ({ ...prev, zoom: evt.viewState.zoom }))}
      onRotate={(evt) => setViewport(prev => ({ ...prev, bearing: evt.viewState.bearing }))}
      onClick={() => setSelectedImei(null)}
    >
      <NavigationControl position="bottom-right" />

      {devices?.map(device => (
        (device.lat && device.lng) ?
          <Marker
            key={device.deviceImei}
            latitude={device.lat}
            longitude={device.lng}
            onClick={(e) => handleSelect(device.deviceImei, e)}
            // rotation={-viewport?.bearing}
          >
            <VehicleMarker
              vehicle={device}
              selected={device.deviceImei == selectedImei}
              zoom={viewport?.zoom}
              angle={viewport?.bearing}
            />
          </Marker>
          : <></>
      ))}

      {trackData &&
        <>
          <Source id="polylineLayer" type="geojson"
            data={trackData}>
            <Layer
              id="lineLayer"
              type="line"
              source="trackData"
              paint={{
                "line-color": "#005EEC",
                "line-width": 5
              }}
            />
          </Source>
          {(trackPoints && trackPoints.length > 0) &&
            <>
              <Marker
                latitude={trackPoints[0].lat}
                longitude={trackPoints[0].lng}
                color="green" />
              <Marker
                latitude={trackPoints[trackPoints.length - 1].lat}
                longitude={trackPoints[trackPoints.length - 1].lng}
                color="red" />
            </>
          }
        </>
      }
    </MapGL>
  );
};

export default MapBoxMap;
