import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { getCountries } from "../../../utils/globals";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import "./EditCompany.css";
import { notification } from "antd";
import { updateCompany, uploadAvatar } from "../../../services/axios";

const EditCompany = () => {
  const getData = useLocation();
  const navigate = useNavigate();

  const CountryData = getCountries();

  const [phoneNumber, setPhoneNumber] = useState(getData.state.companyInfo?.mobile);
  const [suggestions, setSuggestions] = useState([]);
  const [logoPath, setLogoPath] = useState(getData.state.companyInfo?.logo);
  const [formData, setFormData] = useState({
    name: getData.state.companyInfo.name,
    owner: getData.state.companyInfo.owner,
    email: getData.state.companyInfo.email,
    country: getData.state.companyInfo.country,
    address: getData.state.companyInfo.address,
  })

  useEffect(() => {
    const func = debounce(async () => {
      let temp = [];
      if (formData.country) {
        let count = 0;
        let maxCount = 1000;
        let issame = false;
        for (let i = 0; i < CountryData.length; i++) {
          if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase() === formData.country.toLowerCase())
            issame = true;
          if ((CountryData[i].city + ", " + CountryData[i].country).toLowerCase().startsWith(formData.country.toLowerCase())) {
            temp.push({
              state: CountryData[i].city,
              country: CountryData[i].country,
            });
            count++;
          }
          if (count > maxCount) {
            break;
          }
        }
        if (issame) {
          setSuggestions([]);
        } else {
          setSuggestions(temp);
        }
      } else {
        setSuggestions([]);
      }
    }, 500);

    func();
    return () => {
      func.cancel();
    };
  }, [formData.country])

  const handleNumber = (e) => {
    setPhoneNumber(e)
  };

  const handlData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handlSubmit = () => {
    let msg = null;
    if (formData.name.length === 0) {
      msg = "Please input company name!";
    } else if (formData.owner.length === 0) {
      msg = "Please input owner!";
    } else if (formData.email.length === 0) {
      msg = "Please input company email!";
    } else if (phoneNumber.length === 0) {
      msg = "Please input mobile number!";
    } else if (formData.country.length === 0) {
      msg = "Please input country";
    } else if (formData.address.length === 0) {
      msg = "Please input address!";
    }

    if (msg != null) {
      notification.warning({
        description: msg,
      });
      return;
    }
    final()
  }

  const final = async () => {
    const sendData = {
      name: formData.name,
      owner: formData.owner,
      email: formData.email,
      mobile: phoneNumber,
      country: formData.country,
      address: formData.address,
      logo: logoPath
    }
    const res = await updateCompany(getData.state.companyInfo?._id, sendData);
    if (res?.status == 200) {
      notification.success({
        description: "Company has been updated successfully!",
      });
      setTimeout(() => {
        navigate("/Company");
      }, 1000);
    }
    else {
      notification.error({
        description: res?.data.message,
      });
    }
  }

  const onChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const result = await uploadAvatar(selectedFile);
      if (result.status == 200) {
        setLogoPath(result.data.filePath);
      }
    }
  }

  return (
    <div className="edit-company-main w-100 h-100">
      <p>Edit Company</p>
      <div className="edit-company-div1 d-flex justify-content-between flex-column p-5 mx-5" style={{ height: "calc(100vh - 200px)" }}>
        <div className="d-flex justify-content-center flex-column">

          <div className="person-edit-company-sub1-edit-company-div1 d-flex justify-content-center position-relative mb-5">
            <input
              type='file'
              className='personfile position-absolute'
              accept='image/*'
              onChange={onChange}
            />
            <img
              crossOrigin='*'
              src={logoPath ? process.env.REACT_APP_URL + '/' + logoPath : '/assets/uber.png'}
              alt="none"
              className="person-edit-company"
            />
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-5'>
            <div className='d-flex flex-column'>
              <label>Company Name</label>
              <input className='normal-input' value={formData.name} type='text' name="name" onChange={handlData} />
            </div>
            <div className="sub2-subsub1-sub1-edit-company-div1 d-flex flex-column position-relative">
              <label>Owner Name</label>
              <input className='normal-input' value={formData.owner} type='text' name="owner" onChange={handlData} />
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-5'>
            <div className='d-flex flex-column'>
              <label>Email</label>
              <input className='normal-input' value={formData.email} type='email' name="email" onChange={handlData} />
            </div>
            <div className='d-flex flex-column'>
              <label>Mobile No.</label>
              <div className='input-item'>
                <PhoneNumberInput
                  value={phoneNumber}
                  onChange={handleNumber}
                />
              </div>
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-5'>
            <div className='d-flex flex-column position-relative'>
              <label>Country & City</label>
              <input className='normal-input' name='country' type='text' value={formData.country} onChange={handlData} required />
              {formData.country && (
                <div className='suggestion position-absolute w-100 px-3 overflow-y-auto bg-white' style={{ top: "7rem" }}>
                  {suggestions.map((cityData, index) => (
                    <p
                      className='my-1 cursor-pointer p-0'
                      key={index}
                      onClick={() => {
                        setSuggestions([]);
                        setFormData({ ...formData, "country": cityData.state + ", " + cityData.country });
                      }}
                    >
                      {cityData.state + ", " + cityData.country}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className='d-flex flex-column'>
              <label>Address</label>
              <input className='normal-input' type='text' value={formData.address} name="address" onChange={handlData} />
            </div>
          </div>
        </div>
        <div className="subsub5-sub1-edit-user-div1 d-flex justify-content-center">
          <button className='me-5' onClick={() => navigate("/Company")}>Cancel</button>
          <button onClick={() => handlSubmit()}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
