import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelLedIndication = ({token, deviceImei, commandResponse}) => {
  
  const [led, setLed] = useState(false);
  const [updatedDate, setUpdatedDate] = useState("")

  const sendLedIndication = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.LedIndication,
      params: {
        on: led ? "1" : "0"
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.LedIndication,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setLed(parseInt(response.led) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.LedIndication) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="LED Indication"
      description=""
      date={updatedDate}
      onSendAction={sendLedIndication}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="input-element border-0 d-flex justify-content-center mb-3">
            <Switch
              value={led}
              onChange={(checked) => setLed(checked)}
              suffix={"Enabled"}
            />
          </div>
        </div>
      )} />
  );
};

export default PanelLedIndication;
