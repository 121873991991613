import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faBackward, faPlay, faForward, faPause, faMinus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";

import { notification } from "antd";
import { getHistoryList } from "../../services/axios";
import { formatDateTime, formatTimeFromSec, getAngleBetweenPoints, sleep } from "../../utils/globals";
import MapContainer from "../commons/maps/MapContainer";
import "./History.css";

const PlaySpeeds = [1, 5, 10, 20, 60, 600];

const History = () => {

  const getData = useLocation();
  const data = {
    vehicleInfo: getData.state?.vehicleInfo,
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isHistoryPlay, setIsHistoryPlay] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [sliderMaxValue, setSliderMaxValue] = useState(100);
  const [sliderValue, setSliderValue] = useState(0);
  const [playSpeed, setPlaySpeed] = useState(1);

  const [device, setDevice] = useState(null);

  const handleSearch = async () => {
    if (startDate === "" || startDate === null) {
      notification.warning({ description: "Please select the Start Date!" });
      return;
    }
    if (endDate === "" || endDate === null) {
      notification.warning({ description: "Please select the End Date!" });
      return;
    }

    setIsLoading(true);
    const res = await getHistoryList({
      deviceImei: data?.vehicleInfo?.deviceImei,
      startDate: startDate,
      endDate: endDate
    });
    if (res.status == 200 && res.data.result.length > 0) {
      setIsHistoryPlay(true);
      setHistoryData(res.data.result);
      setDevice({
        ...(data.vehicleInfo),
        lat: res.data.result[0].lat,
        lng: res.data.result[0].lng,
        speed: res.data.result[0].speed,
        angle: getAngleBetweenPoints(res.data.result[0].lat, res.data.result[0].lng, res.data.result[1]?.lat, res.data.result[1]?.lng)
      });
      // calc slider value
      let diff = new Date(res.data.result[res.data.result.length - 1].transferDate).getTime() - new Date(res.data.result[0].transferDate).getTime();
      setSliderMaxValue(diff / 1000);
    }
    setIsLoading(false);
  };

  const handleClosePlayback = () => {
    setIsHistoryPlay(false);
    setHistoryData([]);
    setSliderValue(0);
    setDevice(null);
    setIsPlaying(false);
  }

  const handleDecSpeed = () => {
    if (playSpeed > 1) {
      setPlaySpeed(PlaySpeeds[PlaySpeeds.indexOf(playSpeed) - 1]);
    }
  }

  const handleIncSpeed = () => {
    if (playSpeed < 600) {
      setPlaySpeed(PlaySpeeds[PlaySpeeds.indexOf(playSpeed) + 1]);
    }
  }
  const handleStartStop = () => {
    setIsPlaying(!isPlaying);
  }
  const handleSlider = (e) => {
    setSliderValue(Number(e.target.value));
  }

  useEffect(() => {
    const updateVehiclePos = debounce(async () => {
      const len = historyData.length;
      if (len == 0) {
        return;
      }

      const startTime = new Date(historyData[0].transferDate).getTime() / 1000;
      for (let i = 1; i < len; i++) {
        const trackData = historyData[i];

        let nextTime = new Date(trackData.transferDate).getTime() / 1000;
        if (sliderValue <= nextTime - startTime) {
          let prevData = historyData[i - 1];
          let prevLat = prevData.lat;
          let prevLng = prevData.lng;
          let nextLat = trackData.lat;
          let nextLng = trackData.lng;

          let prevTime = new Date(prevData.transferDate).getTime() / 1000;

          let total = nextTime - prevTime;
          let offset = sliderValue + startTime - prevTime;
          let percent = offset / total;

          let curLat = prevLat + (nextLat - prevLat) * percent;
          let curLng = prevLng + (nextLng - prevLng) * percent;

          // console.log(curLat, curLng, nextLat, nextLng, percent);

          setDevice({
            ...(data.vehicleInfo),
            lat: curLat,
            lng: curLng,
            speed: prevData.speed,
            status: "Connected",
            angle: getAngleBetweenPoints(prevLat, prevLng, nextLat, nextLng)
          });
          break;
        }
      }

    }, 10);
    updateVehiclePos();

    return () => {
      updateVehiclePos.cancel();
    };

  }, [sliderValue]);

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setSliderValue(prevVal => {
          if (prevVal + playSpeed >= sliderMaxValue) {
            setIsPlaying(false);
            return sliderMaxValue;
          } else {
            return prevVal + playSpeed;
          }
        })
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPlaying, playSpeed]);


  return (
    <div className="map-container position-relative d-flex justify-content-center w-100">
      {isHistoryPlay ?
        <>
          <div className="history-playback-panel">
            <h3>History Playback</h3>
            <div>{data?.vehicleInfo?.vehicleName}</div>
            <div>
              {formatDateTime(startDate)} - {formatDateTime(endDate)}
            </div>
            <img src="/assets/close.svg" alt="none"
              className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
              onClick={() => handleClosePlayback()} />
          </div>

          <div className="player-main">
            <div className="left-player-main">
              <div className="sub1-player-main-div1">
                <div className="subsub1-sub1-player-main-div1" onClick={handleDecSpeed}>
                  <FontAwesomeIcon icon={faBackward} />
                </div>
                <div
                  className="subsub2-sub1-player-main-div1"
                  onClick={() => handleStartStop()}
                >
                  <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </div>
                <div className="subsub3-sub1-player-main-div1" onClick={handleIncSpeed}>
                  <FontAwesomeIcon icon={faForward} />
                </div>
              </div>
              <div className="sub2-player-main-div1">
                <p >{playSpeed + "x"}</p>
              </div>
            </div>

            <div className="right-player-main">
              <div className="sub1-right-player-main">
                <div className="subsub1-sub1-right-player-main">
                  {formatTimeFromSec(sliderValue)} / {formatTimeFromSec(sliderMaxValue)}
                </div>
                <div className="subsub2-sub1-right-player-main">
                  <input type="range" className="range" min={0} max={sliderMaxValue} value={sliderValue}
                    style={{ "--min": 0, "--max": sliderMaxValue, "--value": sliderValue }}
                    onChange={(e) => { handleSlider(e) }} />
                </div>
              </div>
              <div className="sub2-right-player-main">
                <p>{formatDateTime(startDate)}</p>
                <span><FontAwesomeIcon icon={faMinus} /></span>
                <p>{formatDateTime(endDate)}</p>
              </div>
            </div>
          </div>
        </>
        :
        <div className="w-100 history-search-panel-wrapper">
          <div className="d-flex justify-content-between align-items-center position-relative gap-4 history-search-panel">
            {isLoading && (
              <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center text-light bg-dark bg-opacity-75 rounded-4 z-3">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              </div>
            )}
            <div className="d-flex align-items-center gap-4 ms-3 mb-0 mb-sm-5">
              <img src="/assets/Searchwithperson.svg" alt="none" className="history-search-icon" />
              <span className="panel-title">Search Vehicle History</span>
            </div>

            <div className="d-flex align-items-center gap-5 mb-0 mb-sm-5">
              <span className="vehicle-name">{data?.vehicleInfo?.vehicleName}</span>
              <img className="history-car-icon" alt="none"
                src={`/assets/${data?.vehicleInfo.vehicleType}.svg`} />
            </div>

            <div className="d-flex flex-column flex-sm-row align-items-center gap-4 gap-sm-5">
              <div>
                <div className="">From</div>
                <div className="field-input position-relative">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    shouldCloseOnSelect={false}
                    showTimeInput
                  />
                  <FontAwesomeIcon
                    className="position-absolute top-0 end-0 mt-2 me-3"
                    icon={faCalendarDays} />
                </div>
              </div>
              <div>
                <div className="">To</div>
                <div className="field-input position-relative">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    shouldCloseOnSelect={false}
                    showTimeInput
                  />
                  <FontAwesomeIcon
                    className="position-absolute top-0 end-0 mt-2 me-3"
                    icon={faCalendarDays} />
                </div>
              </div>
            </div>

            <div className="tab-button d-flex justify-content-center align-items-center mx-1" onClick={handleSearch}>
              <img src="/assets/Searchwithpersonwhite.svg" alt="none" />
              <button>Search</button>
            </div>
          </div>
        </div>
      }

      <MapContainer devices={device ? [device] : []} point={historyData?.length > 0 ? historyData[0] : null} trackPoints={historyData} />
    </div>
  );
};

export default History;
