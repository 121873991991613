import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import TextInput from "../../commons/input/TextInput";
import Try from "../try/Try";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelBLECommonSettings = ({token, deviceImei, commandResponse}) => {
  
  const [enableNonStopScan, setEnableNonStopScan] = useState("")
  const [updateFrequency, setUpdateFrequency] = useState("")
  const [bleScanDuration, setBleScanDuration] = useState("")
  const [scanRetriedUntilError, setScanRetriedUntilError] = useState("")
  const [btPowerLevel, setBtPowerLevel] = useState("")
  const [bleBroadcasting, setBleBroadcasting] = useState("")
  const [bleConectionControl, setBleConectionControl] = useState(false)
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendBleCommonSetting = async () => {
    return sendCommonRequest(cmdType.BLEcommonSetting, {
      enableNonStopScan: enableNonStopScan ? 1 : 0,
      updateFrequency: updateFrequency,
      bleScanDuration: bleScanDuration,
      scanRetriedUntilError: scanRetriedUntilError,
      btPowerLevel: btPowerLevel,
      bleBroadcasting: bleBroadcasting,
      bleConectionControl: bleConectionControl,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.BLEcommonSetting,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setEnableNonStopScan(parseInt(response.enableNonStopScan) === 1);
    setUpdateFrequency(response.updateFrequency);
    setBleScanDuration(response.bleScanDuration);
    setScanRetriedUntilError(response.scanRetriedUntilError);
    setBtPowerLevel(response.btPowerLevel);
    setBleBroadcasting(response.bleBroadcasting);
    setBleConectionControl(parseInt(response.bleConectionControl) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.BLEcommonSetting) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="BLE common settings"
      date={updatedDate}
      onSendAction={sendBleCommonSetting}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="input-element border-0 position-relative d-flex justify-content-start align-items-center mb-3">
            <Switch
              value={enableNonStopScan}
              onChange={(checked) => setEnableNonStopScan(checked)}
              prefix={<span className="pe-5 me-5">Enable Non Stop Scan</span>}
              suffix={"ON"}
            />
          </div>
          <TextInput
            name="Sensors and Beacons Update frequency"
            type="number"
            suffix="Seconds"
            value={updateFrequency}
            options={{ min: 30, max: 65535 }}
            onChange={(value) => setUpdateFrequency(value)} />
          <TextInput
            name="BLE Scan Duration"
            type="number"
            suffix="Seconds"
            value={bleScanDuration}
            onChange={(value) => setBleScanDuration(value)} />
          <TextInput
            name="Scan Retried until Error"
            type="number"
            value={scanRetriedUntilError}
            onChange={(value) => setScanRetriedUntilError(value)} />
          <TextInput
            name="BT Power Level"
            type="number"
            value={btPowerLevel}
            onChange={(value) => setBtPowerLevel(value)} />
          <TextInput
            name="BLE Broadcasting Service ID"
            type="number"
            value={bleBroadcasting}
            onChange={(value) => setBleBroadcasting(value)} />
          <div className="input-element border-0 position-relative d-flex justify-content-start align-items-center mb-3">
            <Switch
              value={bleConectionControl}
              onChange={(checked) => setBleConectionControl(checked)}
              suffix={"BLE Connection Control"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"If enabled - BLE connections are allowed to device. If disabled - then not allowed. When disabled - device can work as beacon."} />
            </div>
          </div>
        </div>
      )} />
  );
};

export default PanelBLECommonSettings;
