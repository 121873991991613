import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PanelEnableTLS from "./PanelEnableTLS";
import PanelFOTAWeb from "./PanelFOTAWeb";
import PanelGPRSSecondServerSetup from "./PanelGPRSSecondServerSetup";
import PanelGPRSServerSetup from "./PanelGPRSServerSetup";
import PanelGPRSSetup from "./PanelGPRSSetup";
import PanelNetworkPing from "./PanelNetworkPing";
import PanelRecordsParameter from "./PanelRecordsParameter";
import PanelMQTTSettings from "./PanelMQTTSettings";
import { getCommandSettingsById } from "../../../services/axios";
import { socket } from "../../../socket";


const Connectivity = () => {
  
  const token = useSelector((state) => state.auth.token);
  
  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
  }

  const [mqttSelected1, setMQTTSelected1] = useState(false);
  const [mqttSelected2, setMQTTSelected2] = useState(false);


  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <PanelRecordsParameter token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelEnableTLS token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelFOTAWeb token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelNetworkPing token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelGPRSServerSetup token={token} deviceImei={data?.deviceImei} onMQTTSelected={(value) => setMQTTSelected1(value)} commandResponse={commandResponse} />
        {(mqttSelected1 || mqttSelected2) &&
          <PanelMQTTSettings token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        }
      </div>

      <div className="col-md-4">
        <PanelGPRSSetup token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelGPRSSecondServerSetup token={token} deviceImei={data?.deviceImei} onMQTTSelected={(value) => setMQTTSelected2(value)} commandResponse={commandResponse} />
      </div>
    </div>
  );
};

export default Connectivity;
