import React from 'react';
import { render } from "react-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "react-datepicker/dist/react-datepicker.css";

import { Provider } from 'react-redux';
import store from './redux/store';

import App from './App';

const root = document.getElementById("root");
render(<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
</React.StrictMode>, root);

reportWebVitals();
