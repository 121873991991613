import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelMotionDetectionSource = ({token, deviceImei, commandResponse}) => {
  
  const [ignation, setIgnation] = useState(false)
  const [movementSource, setMovementSource] = useState(false)
  const [gps, setGps] = useState(false)
  const [canSpeed, setCanSpeed] = useState(false)

  const [updatedDate, setUpdatedDate] = useState("")

  const sendMotionSource = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.MotionDetection,
      params: {
        opt: (ignation && 1) | (movementSource && 2) | (gps && 4) | (canSpeed && 8)
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.MotionDetection,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setIgnation(response.opt & 1);
    setMovementSource(response.opt & 2);
    setGps(response.opt & 4);
    setCanSpeed(response.opt & 8);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.MotionDetection) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Motion Detection Source"
      description="Object motion detection is to be configured to determine device working mode. Other functionalities that depend on movement source are: power manager, fuel consumption and trip."
      date={updatedDate}
      onSendAction={sendMotionSource}
      onRefreshAction={refreshAction}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-column align-items-start justify-content-center py-3" style={{ paddingLeft: "40%" }}>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={ignation}
              onChange={(checked) => setIgnation(checked)}
              suffix={"Ignition"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={movementSource}
              onChange={(checked) => setMovementSource(checked)}
              suffix={"Movement Sensor"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={gps}
              onChange={(checked) => setGps(checked)}
              suffix={"GPS"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={canSpeed}
              onChange={(checked) => setCanSpeed(checked)}
              suffix={"CAN Speed"}
              />
          </div>
        </div>
      )} />
  );
};

export default PanelMotionDetectionSource;
