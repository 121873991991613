import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelCanParameters = ({token, deviceImei, commandResponse}) => {
  
  const [numberOfDtc, setNumberOfDtc] = useState(false);
  const [engineLoad, setEngineLoad] = useState(false);
  const [coolant, setCoolant] = useState(false);
  const [shortFuel, setShortFuel] = useState(false);
  const [fuelPressure, setFuelPressure] = useState(false);
  const [intakeMap, setIntakeMap] = useState(false);
  const [enginePrm, setEnginePrm] = useState(false);
  const [vehicleSpeed, setVehicleSpeed] = useState(false);
  const [timingAdvance, setTimingAdvance] = useState(false);
  const [intakeAir, setIntakeAir] = useState(false);
  const [massAir, setMassAir] = useState(false);
  const [throttle, setThrottle] = useState(false);
  const [runTime, setRuntime] = useState(false);
  const [distance, setDistance] = useState(false);
  const [relativeFuel, setRelativeFuel] = useState(false);
  const [directFuel, setDirectFuel] = useState(false);
  const [commandedEgr, setCommandEgr] = useState(false);
  const [egrError, setEgrError] = useState(false);
  const [fuelLevel, setFuelLevel] = useState(false);
  const [distanceTraveled, setDistanceTraveled] = useState(false);
  const [barometric, setBarometric] = useState(false);
  const [ctlVoltage, setCtlVoltage] = useState(false);
  const [absoluteLoad, setAbsoluteLoad] = useState(false);
  const [ambient, setAmbient] = useState(false);
  const [timeRun, setTimeRun] = useState(false);
  const [timeSince, setTimeSince] = useState(false);
  const [absoluteFuel, setAbsoluteFuel] = useState(false);
  const [hybridBattery, setHybridBattery] = useState(false);
  const [engineOil, setEngineOil] = useState(false);
  const [fuelInjection, setFuelInjection] = useState(false);
  const [engineFuelRate, setEngineFuelRate] = useState(false);
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendCanParameters = async () => {
    return sendCommonRequest(cmdType.CanParam, {
      p1: numberOfDtc ? "1" : "0",
      p2: engineLoad ? "1" : "0",
      p3: coolant ? "1" : "0",
      p4: shortFuel ? "1" : "0",
      p5: fuelPressure ? "1" : "0",
      p6: intakeMap ? "1" : "0",
      p7: enginePrm ? "1" : "0",
      p8: vehicleSpeed ? "1" : "0",
      p9: timingAdvance ? "1" : "0",
      p10: intakeAir ? "1" : "0",
      p11: massAir ? "1" : "0",
      p12: throttle ? "1" : "0",
      p13: runTime ? "1" : "0",
      p14: distance ? "1" : "0",
      p15: relativeFuel ? "1" : "0",
      p16: directFuel ? "1" : "0",
      p17: commandedEgr ? "1" : "0",
      p18: egrError ? "1" : "0",
      p19: fuelLevel ? "1" : "0",
      p20: distanceTraveled ? "1" : "0",
      p21: barometric ? "1" : "0",
      p22: ctlVoltage ? "1" : "0",
      p23: absoluteLoad ? "1" : "0",
      p24: ambient ? "1" : "0",
      p25: timeRun ? "1" : "0",
      p26: timeSince ? "1" : "0",
      p27: absoluteFuel ? "1" : "0",
      p28: hybridBattery ? "1" : "0",
      p29: engineOil ? "1" : "0",
      p30: fuelInjection ? "1" : "0",
      p31: engineFuelRate ? "1" : "0",
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.CanParam,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setNumberOfDtc(parseInt(response.p1) === 1);
    setEngineLoad(parseInt(response.p2) === 1);
    setCoolant(parseInt(response.p3) === 1);
    setShortFuel(parseInt(response.p4) === 1);
    setFuelPressure(parseInt(response.p5) === 1);
    setIntakeMap(parseInt(response.p6) === 1);
    setEnginePrm(parseInt(response.p7) === 1);
    setVehicleSpeed(parseInt(response.p8) === 1);
    setTimingAdvance(parseInt(response.p9) === 1);
    setIntakeAir(parseInt(response.p10) === 1);
    setMassAir(parseInt(response.p11) === 1);
    setThrottle(parseInt(response.p12) === 1);
    setRuntime(parseInt(response.p13) === 1);
    setDistance(parseInt(response.p14) === 1);
    setRelativeFuel(parseInt(response.p15) === 1);
    setDirectFuel(parseInt(response.p16) === 1);
    setCommandEgr(parseInt(response.p17) === 1);
    setEgrError(parseInt(response.p18) === 1);
    setFuelLevel(parseInt(response.p19) === 1);
    setDistanceTraveled(parseInt(response.p20) === 1);
    setBarometric(parseInt(response.p21) === 1);
    setCtlVoltage(parseInt(response.p22) === 1);
    setAbsoluteLoad(parseInt(response.p23) === 1);
    setAmbient(parseInt(response.p24) === 1);
    setTimeRun(parseInt(response.p25) === 1);
    setTimeSince(parseInt(response.p26) === 1);
    setAbsoluteFuel(parseInt(response.p27) === 1);
    setHybridBattery(parseInt(response.p28) === 1);
    setEngineOil(parseInt(response.p29) === 1);
    setFuelInjection(parseInt(response.p30) === 1);
    setEngineFuelRate(parseInt(response.p31) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.CanParam) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="CAN Parameters"
      description="CAN parameters setup."
      date={updatedDate}
      onSendAction={sendCanParameters}
      onRefreshAction={refreshAction}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-row justify-content-between py-3 px-5 gap-4">
          <div className="d-flex flex-column align-items-start gap-2">
            <Switch
              value={numberOfDtc}
              onChange={(checked) => setNumberOfDtc(checked)}
              suffix={"Number of DTC"}
            />
            <Switch
              value={engineLoad}
              onChange={(checked) => setEngineLoad(checked)}
              suffix={"Engine Load"}
            />
            <Switch
              value={coolant}
              onChange={(checked) => setCoolant(checked)}
              suffix={"Coolant Temperature"}
            />
            <Switch
              value={shortFuel}
              onChange={(checked) => setShortFuel(checked)}
              suffix={"Short Fuel Trim"}
            />
            <Switch
              value={fuelPressure}
              onChange={(checked) => setFuelPressure(checked)}
              suffix={"Fuel Pressure"}
            />
            <Switch
              value={intakeMap}
              onChange={(checked) => setIntakeMap(checked)}
              suffix={"Intake MAP"}
            />
            <Switch
              value={enginePrm}
              onChange={(checked) => setEnginePrm(checked)}
              suffix={"Engine RPM"}
            />
            <Switch
              value={vehicleSpeed}
              onChange={(checked) => setVehicleSpeed(checked)}
              suffix={"Vehicle Speed"}
            />
            <Switch
              value={timingAdvance}
              onChange={(checked) => setTimingAdvance(checked)}
              suffix={"Timing Advance"}
            />
            <Switch
              value={intakeAir}
              onChange={(checked) => setIntakeAir(checked)}
              suffix={"Intake Air Temperature"}
            />
            <Switch
              value={massAir}
              onChange={(checked) => setMassAir(checked)}
              suffix={"Mass Air Flow Rate"}
            />
            <Switch
              value={throttle}
              onChange={(checked) => setThrottle(checked)}
              suffix={"Throttle Position"}
            />
            <Switch
              value={runTime}
              onChange={(checked) => setRuntime(checked)}
              suffix={"Run Time Since Engine Start"}
            />
            <Switch
              value={distance}
              onChange={(checked) => setDistance(checked)}
              suffix={"Distance Traveled Since MIL On"}
            />
            <Switch
              value={relativeFuel}
              onChange={(checked) => setRelativeFuel(checked)}
              suffix={"Relative Fuel Rail Pressure"}
            />
            <Switch
              value={directFuel}
              onChange={(checked) => setDirectFuel(checked)}
              suffix={"Direct Fuel Rail Pressure"}
            />
          </div>
          <div className="d-flex flex-column align-items-start gap-2">
            <Switch
              value={commandedEgr}
              onChange={(checked) => setCommandEgr(checked)}
              suffix={"Commanded EGR"}
            />
            <Switch
              value={egrError}
              onChange={(checked) => setEgrError(checked)}
              suffix={"EGR Error"}
            />
            <Switch
              value={fuelLevel}
              onChange={(checked) => setFuelLevel(checked)}
              suffix={"Fuel Level"}
            />
            <Switch
              value={distanceTraveled}
              onChange={(checked) => setDistanceTraveled(checked)}
              suffix={"Distance Traveled Since Trouble Codes Cleared"}
            />
            <Switch
              value={barometric}
              onChange={(checked) => setBarometric(checked)}
              suffix={"Barometric Pressure"}
            />
            <Switch
              value={ctlVoltage}
              onChange={(checked) => setCtlVoltage(checked)}
              suffix={"Control Module Voltage"}
            />
            <Switch
              value={absoluteLoad}
              onChange={(checked) => setAbsoluteLoad(checked)}
              suffix={"Absolute Load Value"}
            />
            <Switch
              value={ambient}
              onChange={(checked) => setAmbient(checked)}
              suffix={"Ambient Air Temperature"}
            />
            <Switch
              value={timeRun}
              onChange={(checked) => setTimeRun(checked)}
              suffix={"Time Run With MIL On"}
            />
            <Switch
              value={timeSince}
              onChange={(checked) => setTimeSince(checked)}
              suffix={"Time Since Trouble Codes Cleared"}
            />
            <Switch
              value={absoluteFuel}
              onChange={(checked) => setAbsoluteFuel(checked)}
              suffix={"Absolute Fuel Rail Pressure"}
            />
            <Switch
              value={hybridBattery}
              onChange={(checked) => setHybridBattery(checked)}
              suffix={"Hybrid Battery Pack Remaining Life"}
            />
            <Switch
              value={engineOil}
              onChange={(checked) => setEngineOil(checked)}
              suffix={"Engine Oil Temperature"}
            />
            <Switch
              value={fuelInjection}
              onChange={(checked) => setFuelInjection(checked)}
              suffix={"Fuel Injection Timing"}
            />
            <Switch
              value={engineFuelRate}
              onChange={(checked) => setEngineFuelRate(checked)}
              suffix={"Engine Fuel Rate"}
            />
          </div>
        </div>
      )} />
  );
};

export default PanelCanParameters;
