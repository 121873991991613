import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelRecordsParameter = ({token, deviceImei, commandResponse}) => {
  
  const [openLinkTimeOut, setOpenLinkTimeOut] = useState("");
  const [serverResponseTimeOut, setServerResponseTimeOut] = useState("");
  const [recordStoringOrder, setRecordStoringOrder] = useState("Newest First");
  const [ackType, setAckType] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")

  const sendRecordsPrameter = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.RecordsParameters,
      params: {
        linkTimeOut: openLinkTimeOut,
        resTimeOut: serverResponseTimeOut,
        order: recordStoringOrder,
        ackType: ackType,
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.RecordsParameters,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setOpenLinkTimeOut(response.linkTimeOut);
    setServerResponseTimeOut(response.resTimeOut);
    setRecordStoringOrder(response.order);
    setAckType(response.ackType);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.RecordsParameters) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Records Parameters"
      description=""
      date={updatedDate}
      onSendAction={sendRecordsPrameter}
      onRefreshAction={refreshAction}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <TextInput
            name="Open Link Timeout"
            tooltip="Timeout to terminate connection between the device and backend server, 30 - 259200 seconds"
            suffix="Seconds"
            type="number"
            options={{ min: 30, max: 259200 }}
            value={openLinkTimeOut}
            onChange={(value) => setOpenLinkTimeOut(value)} />
          <TextInput
            name="Server Response Timeout"
            tooltip="Timeout to wait response from backend server, 5 - 300 seconds"
            suffix="Seconds"
            type="number"
            options={{ min: 5, max: 300 }}
            value={serverResponseTimeOut}
            onChange={(value) => setServerResponseTimeOut(value)} />
          <SelectBox 
            name="Records Storing Order"
            value={recordStoringOrder}
            onChange={(e) => setRecordStoringOrder(e.target.value)}
            options={[ 
              { value: "", label: "" },
              { value: "0", label: "Newest First" },
              { value: "1", label: "Oldest First" },
            ]}
            tooltip={"The parameter which defines how records are sent to the server"}
            />
          <SelectBox 
            name="ACK Type"
            value={ackType}
            onChange={(e) => setAckType(e.target.value)}
            options={[ 
              { value: "", label: "" },
              { value: "0", label: "TCP/IP" },
              { value: "1", label: "AVL" },
            ]}
            />
        </div>
      )} />
  );
};

export default PanelRecordsParameter;
