import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import { formatDateTime } from "../../../utils/globals";
import TextInput from "../../commons/input/TextInput";
import Switch from "../../commons/switch/Switch";
import { sendCommandMsg } from "../../../socket";

const PanelTrackingOnMode = ({token, deviceImei, commandResponse}) => {
  
  const [din1, setDin1] = useState(false);
  const [din2, setDin2] = useState(false);
  const [din3, setDin3] = useState(false);
  const [ain1, setAin1] = useState(false);
  const [ain2, setAin2] = useState(false);
  const [track_time_period, setTrackTimePeriod] = useState("");
  const [track_time_duration, setTrackTimeDuration] = useState("");

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendTrackingOnDemandMode = async () => {
    return sendCommonRequest(cmdType.TrackingOnDemandMode, {
      period: track_time_period,
      duration: track_time_duration,
      activation: din1 ? 1 : 0
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.TrackingOnDemandMode,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setTrackTimePeriod(response.period);
    setTrackTimeDuration(response.duration);
    setDin1(parseInt(response.activation) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.TrackingOnDemandMode) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Tracking on demand mode"
      description="Push device to start to generate high priority records and initiate data sending to server"
      date={updatedDate}
      onSendAction={sendTrackingOnDemandMode}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={din1}
              onChange={(checked) => setDin1(checked)}
              suffix={"DIN1"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={din2}
              onChange={(checked) => setDin2(checked)}
              suffix={"DIN2"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={din3}
              onChange={(checked) => setDin3(checked)}
              suffix={"DIN3"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={ain1}
              onChange={(checked) => setAin1(checked)}
              suffix={"AIN1"}
              />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={ain2}
              onChange={(checked) => setAin2(checked)}
              suffix={"AIN2"}
              />
          </div>
          
          <TextInput
            name="Tracking Period"
            suffix="Seconds"
            type="number"
            value={track_time_period}
            onChange={(value) => setTrackTimePeriod(value)} />
          <TextInput
            name="Tracking Duration"
            suffix="Seconds"
            type="number"
            value={track_time_duration}
            onChange={(value) => setTrackTimeDuration(value)} />
        </div>
      )} />
  );
};

export default PanelTrackingOnMode;
