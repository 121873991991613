import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelNetworkPing = ({token, deviceImei, commandResponse}) => {
  
  const [networkPing, setNetworkPing] = useState("");
  const [updatedDate, setUpdatedDate] = useState("")

  const sendNetworkingPing = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.NetworkPing,
      params: {
        tout: networkPing
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.NetworkPing,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setNetworkPing(response.tout);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.NetworkPing) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Network Ping"
      description="Set up network ping with a certain timeout to prevent link close by the operator."
      date={updatedDate}
      onSendAction={sendNetworkingPing}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <p className="w-100">Network Ping</p>
          <TextInput
            name="Timeout"
            tooltip="Network ping timeout, range 0 - 300 seconds"
            suffix="Seconds"
            type="number"
            value={networkPing}
            onChange={(value) => setNetworkPing(value)} />
        </div>
      )} />
  );
};

export default PanelNetworkPing;
