import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Modal, notification } from "antd";

import { createTicket, replyTicket, updateTicketStatus, uploadTicketFile } from "../../../services/axios";
import { formatDateTime, showConfirmEx } from "../../../utils/globals";

const ViewTicket = ({ ticket, isShowModal, onClose, onSuccess, onCloseTicket }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setContent("");
    setFiles([]);
  }, [isShowModal]);

  const handleUploadFile = async (e) => {
    if (files.length >= 5) {
      notification.error({
        description: "Can't upload more than 5 files",
      });
      return;
    }

    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 20 * 1024 * 1024) {
        notification.error({
          description: "Can't upload file size is bigger than 20Mb.",
        });
        return;
      }

      const result = await uploadTicketFile(selectedFile);
      if (result.status == 200) {
        setFiles([
          ...files,
          {
            name: selectedFile.name,
            path: result.data.filePath
          }
        ]);
      }
    }
  }

  const handleRemoveFile = (e, index) => {
    e.stopPropagation();
    let newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  const handlCloseTicket = async () => {
    showConfirmEx(`Are you sure?`)
      .then(async () => {
        const sendData = {
          id: ticket._id,
          status: "Resolved"
        };
        let res = await updateTicketStatus(sendData);
        if (res.status === 200) {
          onCloseTicket(ticket._id);
        } else if (res.data.message) {
          notification.error({
            description: res.data.message,
          });
        }
      })
      .catch(() => {
      });
  }

  const handlSubmit = async () => {
    let msg = null;
    if (content.length === 0) {
      msg = "Please input the content";
    }
    if (msg != null) {
      notification.error({
        description: msg,
      });
      return;
    }

    const res = await replyTicket(ticket._id, content, files);
    if (res.status == 200) {
      notification.success({
        description: "Replied successfully!",
      });
      onSuccess(res.data.result);
    } else {
      notification.error({
        description: "Something went wrong!",
      });
    }
  };

  return (
    <Modal
      title={(
        <div className="text-center fw-bold position-relative" style={{ fontSize: "2rem", color: "#D01400" }}>
          View Ticket
          <span className="fw-normal position-absolute top-0 start-0 mt-2 ms-4" style={{ fontSize: "1.6rem", textDecoration: "underline" }}>{ticket?.referenceNo}</span>
        </div>)}
      centered
      open={isShowModal}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      width={600}
      style={{ maxWidth: "100%" }}
      className='ticket-edit-modal'
      footer={false}
    >
      <div className="new-ticket-main justify-content-center flex-column p-4">
        <div className="d-flex flex-column overflow-auto">
          <div className="sub1-subsub1-sub1-new-ticket-div1 d-flex flex-column">
            <label>Subject</label>
            <input
              className="w-100"
              type="text"
              value={ticket?.subject}
              onChange={(e) => { }}
              disabled
            />
          </div>
          {ticket?.messages?.map((message, index) => (
            <>
              <div className="sub2-subsub1-sub1-new-ticket-div1 d-flex flex-column mt-3">
                <div className="d-flex justify-content-between">
                  <label>Message</label>
                  <span>{message?.user} - {formatDateTime(message?.createdAt)}</span>
                </div>
                <textarea
                  className="w-100"
                  style={{ height: "fit-content" }}
                  type="text"
                  value={message?.content}
                  onChange={() => { }}
                  disabled
                />
              </div>
              <div className="d-flex flex-column mt-3 gap-2">
                {message?.files.map((item, index) => {
                  return (
                    <div key={index} className="sub1-left-subsub2-sub1-new-ticket-div1" style={{ width: "fit-content", minWidth: "20rem" }}>
                      <a href={process.env.REACT_APP_URL + '/' + item.path} target="_blank">{item.name}</a>
                    </div>
                  );
                })}
              </div>
            </>
          ))}
        </div>

        {ticket?.status == "Pending" &&
          <>
            <div className="sub2-subsub1-sub1-new-ticket-div1 d-flex flex-column mt-4">
              <label>Reply</label>
              <textarea
                className="w-100"
                type="text"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div className={`d-flex w-100 my-5 justify-content-between ${isMobile && 'flex-column gap-5'}`}>
              <div className="d-flex flex-column">
                {files.map((item, index) => {
                  return (
                    <div key={index} className="sub1-left-subsub2-sub1-new-ticket-div1 position-relative my-2">
                      <a href={process.env.REACT_APP_URL + '/' + item.path} target="_blank">{item.name}</a>
                      <img src="/assets/redCross.svg" alt="none" onClick={(e) => handleRemoveFile(e, index)} />
                    </div>
                  );
                })}
              </div>
              <span className="sub1-center-subsub2-sub1-new-ticket-span">File size should Not be more than 20MB</span>
              <div className="sub1-right-subsub2-sub1-new-ticket-div1 position-relative">
                <img src="/assets/file.svg" alt="none" />
                <input className="position-absolute" type="file" onChange={handleUploadFile} />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-3 mt-5">
              <button className="tab-button btn-success text-white" onClick={() => handlCloseTicket()}>Close Ticket</button>
              <button className="tab-button text-white" onClick={() => handlSubmit()}>Reply</button>
            </div>
          </>
        }
      </div>
    </Modal>
  );
};

export default ViewTicket;
