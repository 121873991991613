import React, { useEffect, useState } from "react";
import { CommandPriorities, cmdType, getCommandPhones } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import Try from "../try/Try";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelExcessiveDetection = ({token, deviceImei, commandResponse, gsmNumbers}) => {
  
  const [eid_priority, setEIDPriority] = useState("");
  const [eid_stop_timeout, setEidStopTimeout] = useState("")
  const [eid_record_check, setEidRecordCheck] = useState(false)
  const [eid_move_timeout, setEidMoveTimeout] = useState("")
  const [eid_out_control_check, setEidOutControlCheck] = useState(false)
  const [eid_send_sms_check, setEidSendSmsCheck] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendEidetection = async () => {
    return sendCommonRequest(cmdType.ExcessiveIdlingDetection, {
      priority: eid_priority,
      evRec: eid_record_check ? "1" : "0",
      stopTout: eid_stop_timeout,
      moveTout: eid_move_timeout,
      outputCtl: eid_out_control_check ? "1" : "0",
      p1: "",
      p2: "",
      phoneNo: phoneNumber,
      sms: smsText
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.ExcessiveIdlingDetection,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setEIDPriority(response.priority);
    setEidStopTimeout(response.stopTout);
    setEidRecordCheck(parseInt(response.evRec) === 1);
    setEidMoveTimeout(response.moveTout);
    setEidOutControlCheck(parseInt(response.outputCtl) === 1);
    setEidSendSmsCheck(parseInt(response.phoneNo) > 0);
    setPhoneNumber(response.phoneNo);
    setSmsText(response.sms);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.ExcessiveIdlingDetection) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Excessive Idling Detection"
      description="Excessive idling detection scenario settings"
      date={updatedDate}
      onSendAction={sendEidetection}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Priority"
            value={eid_priority}
            onChange={(e) => setEIDPriority(e.target.value)}
            options={CommandPriorities}
            tooltip={"Excessive idling detection scenario priority"}
          />
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={eid_record_check}
              onChange={(checked) => setEidRecordCheck(checked)}
              suffix={"Event Only"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable/disable eventual records with idling value"} />
            </div>
          </div>
          <TextInput
            name="Stop Timeout"
            tooltip="Timeout of vehicle beeing in idling state before scenario enables"
            type="number"
            suffix="seconds"
            value={eid_stop_timeout}
            onChange={(value) => setEidStopTimeout(value)} />
          <TextInput
            name="Move Timeout"
            tooltip="Timeout of vehicle beeing in moving state before scenario disables"
            type="number"
            suffix="Seconds"
            value={eid_move_timeout}
            onChange={(value) => setEidMoveTimeout(value)} />

          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={eid_out_control_check}
              onChange={(checked) => setEidOutControlCheck(checked)}
              prefix={<span className="pe-5 me-2">Output Control</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable/disable excessive idling output control"} />
            </div>
          </div>
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={eid_send_sms_check}
              onChange={(checked) => setEidSendSmsCheck(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable sending SMS of excessive idling event"} />
            </div>
          </div>
          
          {eid_send_sms_check && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}
        </div>
      )} />
  );
};

export default PanelExcessiveDetection;
