import React, { useEffect, useState } from "react";
import { CommandPriorities, cmdType, getCommandPhones } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import Try from "../try/Try";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelCrashDetection = ({token, deviceImei, commandResponse, gsmNumbers}) => {
  
  const [cd_priority, setCdPriority] = useState("");
  const [cd_duration, setCdDuration] = useState("")
  const [cd_threshold, setCdThreshold] = useState("")
  const [cd_car_trace, setCdCarTrace] = useState("")
  const [cd_send_sms_check, setCdSendSmsCheck] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");
  
  const [crash_enable_check, setCrashEnableCheck] = useState(false);
  const [crash_out_data_rate, setCrashOutDataRate] = useState("");
  const [crash_acc_duration, setCrashAccDuration] = useState("");
  const [crash_gnss_duration, setCrashGnssDuration] = useState("");

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendCrashDetection = async () => {
    return sendCommonRequest(cmdType.CrashDetection, {
      priority: cd_priority,
      duration: cd_duration,
      threshold: cd_threshold,
      carTrace: cd_car_trace,
      phoneNo: phoneNumber,
      sms: smsText,

      ccon: crash_enable_check ? "1" : "0",
      ccrate: crash_out_data_rate,
      ccduration: crash_acc_duration,
      ccgnss: crash_gnss_duration
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.CrashDetection,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setCdPriority(response.priority);
    setCdDuration(response.duration);
    setCdThreshold(response.threshold);
    setCdCarTrace(response.carTrace);
    setCdSendSmsCheck(parseInt(response.phoneNo) > 0);
    setPhoneNumber(response.phoneNo);
    setSmsText(response.sms);

    setCrashEnableCheck(parseInt(response.ccon) === 1);
    setCrashOutDataRate(response.ccrate);
    setCrashAccDuration(response.ccduration);
    setCrashGnssDuration(response.ccgnss);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.CrashDetection) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Crash Detection"
      description="Crash detection scenario settings"
      date={updatedDate}
      onSendAction={sendCrashDetection}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Priority"
            value={cd_priority}
            onChange={(e) => setCdPriority(e.target.value)}
            options={CommandPriorities}
            tooltip={"Crash detection scenario priority"}
          />
          <TextInput
            name="Duration"
            tooltip="Crash detection duration"
            type="number"
            suffix="milliseconds"
            value={cd_duration}
            onChange={(value) => setCdDuration(value)} />
          <TextInput
            name="Threshold"
            tooltip="Value used to detect crash"
            type="number"
            suffix="mG"
            value={cd_threshold}
            onChange={(value) => setCdThreshold(value)} />
          <SelectBox
            name="Car Trace"
            value={cd_car_trace}
            onChange={(e) => setCdCarTrace(e.target.value)}
            options={[
              {value: "", label: ""},
              {value: "0", label: "Disabled"},
              {value: "1", label: "Trace Changes"},
              {value: "2", label: "Trace Full"},
            ]}
          />

          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={cd_send_sms_check}
              onChange={(checked) => setCdSendSmsCheck(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable sending SMS of towing event"} />
            </div>
          </div>
          
          {cd_send_sms_check && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}

          <div className="input-element border-0 d-flex justify-content-start mb-3">
            <Switch
              value={crash_enable_check}
              onChange={(checked) => setCrashEnableCheck(checked)}
              prefix={<span className="pe-5 me-3">Crash Counter</span>}
              suffix={"Enable"}
            />
          </div>
          <TextInput
            name="Crash Even Output Data Rate"
            type="number"
            suffix="Hz"
            value={crash_out_data_rate}
            onChange={(value) => setCrashOutDataRate(value)} />
          <TextInput
            name="Acceleration  Duration"
            tooltip="Crash event monitoring duration of linear accelerometer values before and after event"
            type="number"
            suffix="Seconds"
            value={crash_acc_duration}
            onChange={(value) => setCrashAccDuration(value)} />
          <TextInput
            name="GNSS Duration"
            tooltip="Crash event monitoring duration of GNSS data before and after event"
            type="number"
            suffix="Seconds"
            value={crash_gnss_duration}
            onChange={(value) => setCrashGnssDuration(value)} />
        </div>
      )} />
  );
};

export default PanelCrashDetection;
