import React, { useEffect, useState } from "react";
import "./Bluetooth.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PanelAutoConnect from "./PanelAutoConnect";
import PanelBeaconSettings from "./PanelBeaconSettings";
import PanelBeaconsList from "./PanelBeaconsList";
import PanelBLECommonSettings from "./PanelBLECommonSettings";
import PanelBluetoothWorkMode from "./PanelBluetoothWorkMode";
import PanelBLESerialEncryption from "./PanelBLESerialEncryption";
import PanelEyeSensor from "./PanelEyeSensor";
import { getCommandSettingsById } from "../../../services/axios";
import { socket } from "../../../socket";

const Bluetooth = () => {
  
  const token = useSelector((state) => state.auth.token);
  
  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
  }

  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-4">
        <PanelBluetoothWorkMode token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelBLECommonSettings token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelAutoConnect token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelBeaconsList token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>

      <div className="col-md-4">
        <PanelBeaconSettings token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelBLESerialEncryption token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
        <PanelEyeSensor token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>
    </div>
  );
};

export default Bluetooth;
