import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelEnableTLS = ({token, deviceImei, commandResponse}) => {
  
  const [tlsEnable, setTlsEnable] = useState(false);

  const [updatedDate, setUpdatedDate] = useState("")

  
  const sendEnableConnectionOverTLS = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.EnableConnectionOverTLS,
      params: {
        tls: tlsEnable ? "1" : "0",

      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.RecordsParameters,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setTlsEnable(parseInt(response.tls) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.RecordsParameters) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Enable connection over TLS"
      description=""
      date={updatedDate}
      onSendAction={sendEnableConnectionOverTLS}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <p className="w-100">Enable connection over TLS</p>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Switch 
              value={tlsEnable}
              onChange={(checked) => setTlsEnable(checked)}
              suffix={"TLS Enable"}
              />
          </div>
        </div>
      )} />
  );
};

export default PanelEnableTLS;
