import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import { formatDateTime } from "../../../utils/globals";
import TextInput from "../../commons/input/TextInput";
import { sendCommandMsg } from "../../../socket";

const PanelIgnitionDetection = ({ token, deviceImei, commandResponse }) => {

  const [isrcDin1, setIsrcDin1] = useState(false);
  const [isrcAcceleration, setIsrcAcceleration] = useState(false);
  const [isrcPowerVoltage, setIsrcPowerVoltage] = useState(false);
  const [isrcEngineRPM, setIsrcEngineRPM] = useState(false);
  const [isrcDin3, setIsrcDin3] = useState(false);
  const [highVoltage, setHighVoltage] = useState("0")
  const [lowVoltage, setLowVoltage] = useState("0")
  const [updatedDate, setUpdatedDate] = useState("")

  const sendIgnationDetection = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.IgnitionDetectionSource,
      params: {
        src: (isrcDin1 && 1) | (isrcAcceleration && 2) | (isrcPowerVoltage && 4) | (isrcEngineRPM && 8) | (isrcDin3 && 16),
        high: highVoltage,
        low: lowVoltage,
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.IgnitionDetectionSource,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setIsrcDin1(response.src & 1);
    setIsrcAcceleration(response.src & 2);
    setIsrcPowerVoltage(response.src & 4);
    setIsrcEngineRPM(response.src & 8);
    setIsrcDin3(response.src & 16);
    setHighVoltage(response.high);
    setLowVoltage(response.low);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.IgnitionDetectionSource) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Ignition Detection Source"
      description="Ignition source is configurable and used to determine vehicle ignition status. Ignition status is used in power management and the following functionalities: eco driving, excessive idling, fuel consumption, over speeding, towing and trip."
      date={updatedDate}
      onSendAction={sendIgnationDetection}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5 ignition-source">
          <div className="d-flex flex-column gap-1 mb-3">
            <div className="btn-group" role="group" aria-label="" style={{ width: "20rem" }}>
              <input type="checkbox" className="btn-check" id="btnIgnitionCheck1" checked={isrcDin1} onChange={(e) => setIsrcDin1(e.target.checked)} />
              <label className="btn btn-outline-primary w-50" htmlFor="btnIgnitionCheck1">DIN1</label>

              <input type="checkbox" className="btn-check" id="btnIgnitionCheck2" checked={isrcAcceleration} onChange={(e) => setIsrcAcceleration(e.target.checked)} />
              <label className="btn btn-outline-primary w-50" htmlFor="btnIgnitionCheck2">Acceleration</label>
            </div>
            <div className="btn-group" role="group" aria-label="">
              <input type="checkbox" className="btn-check" id="btnIgnitionCheck3" checked={isrcPowerVoltage} onChange={(e) => setIsrcPowerVoltage(e.target.checked)} />
              <label className="btn btn-outline-primary w-50" htmlFor="btnIgnitionCheck3">Power Voltage</label>

              <input type="checkbox" className="btn-check" id="btnIgnitionCheck4" checked={isrcEngineRPM} onChange={(e) => setIsrcEngineRPM(e.target.checked)} />
              <label className="btn btn-outline-primary w-50" htmlFor="btnIgnitionCheck4">Engine RPM</label>
            </div>
            <div className="btn-group" role="group" aria-label="">
              <input type="checkbox" className="btn-check" id="btnIgnitionCheck5" checked={isrcDin3} onChange={(e) => setIsrcDin3(e.target.checked)} />
              <label className="btn btn-outline-primary w-50" htmlFor="btnIgnitionCheck5">DIN3</label>
              <div className="w-50">&nbsp;</div>
            </div>
          </div>
          
          <div className="sub-panel">
            <TextInput
              name="High Voltage"
              tooltip="Sets high level of voltage if ignition setting is set to power voltage. Minimum = 0, maximum = 30000"
              type="number"
              suffix="mV"
              className="justify-content-between"
              value={highVoltage}
              options={{ min: 0, max: 30000 }}
              onChange={(value) => setHighVoltage(value)} />
            <TextInput
              name="Low Voltage"
              tooltip="Sets low level of voltage if ignition setting is set to power voltage. Minimum = 0, maximum = 12000"
              type="number"
              suffix="mV"
              className="justify-content-between"
              value={lowVoltage}
              options={{ min: 0, max: 12000 }}
              onChange={(value) => setLowVoltage(value)} />
          </div>
        </div>
      )} />
  );
};

export default PanelIgnitionDetection;
