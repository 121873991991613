import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelIgnitionCounter = ({token, deviceImei, commandResponse}) => {
  
  const [ignition_val, setIgnitionVal] = useState("")
  const [ignition_check, setIgnitionCheck] = useState(false)
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendIgnition = async () => {
    return sendCommonRequest(cmdType.IgnitionOnCounter, {
      on: ignition_check ? "1" : "0",
      val: ignition_val
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.IgnitionOnCounter,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setIgnitionVal(response.val);
    setIgnitionCheck(parseInt(response.on) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.IgnitionOnCounter) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Ignition ON counter"
      description="Ignition ON Counter scenario counts the time spent with ignition on in the resolution of seconds."
      date={updatedDate}
      onSendAction={sendIgnition}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="input-element border-0 d-flex justify-content-center mb-3">
            <Switch
              value={ignition_check}
              onChange={(checked) => setIgnitionCheck(checked)}
              suffix={"Enable"}
            />
          </div>
          <TextInput
            name="Counter Value"
            tooltip="Ignition ON Counter value to be used initially. Minimum = 0, Maximum = 2147483647"
            type="number"
            suffix="Seconds"
            value={ignition_val}
            options={{ min: 0, max: 2147483647 }}
            onChange={(value) => setIgnitionVal(value)} />
        </div>
      )} />
  );
};

export default PanelIgnitionCounter;
