import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelRoamingGSM = ({token, deviceImei, commandResponse}) => {
  
  const [rgns_vehicle_drt, setRGNSVehicleDRT] = useState("");
  const [rgns_vehicle_sr, setRGNSVehicleSR] = useState("");
  const [rgns_vehicle_sp, setRGNSVehicleSP] = useState("");
  const [rgns_moving_timeout, setRGNSMovingTimeout] = useState("");
  const [rgns_moving_distance, setRGNSMovingDistance] = useState("");
  const [rgns_moving_ac, setRGNSMovingAC] = useState("");
  const [rgns_moving_sc, setRGNSMovingSC] = useState("");
  const [rgns_moving_sr, setRGNSMovingSR] = useState("");
  const [rgns_moving_sp, setRGNSMovingSP] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendRoamingGSMNetwork = async () => {
    return sendCommonRequest(cmdType.RoamingGsmNetworkSettings, {
      recTout: rgns_vehicle_drt,
      saveRec: rgns_vehicle_sr,
      sendPeriod: rgns_vehicle_sp,
      tout: rgns_moving_timeout,
      distance: rgns_moving_distance,
      angChg: rgns_moving_ac,
      speedChg: rgns_moving_sc,
      msavRec: rgns_moving_sr,
      msendPeriod: rgns_moving_sp
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.RoamingGsmNetworkSettings,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setRGNSVehicleDRT(response.recTout);
    setRGNSVehicleSR(response.saveRec);
    setRGNSVehicleSP(response.sendPeriod);
    setRGNSMovingTimeout(response.tout);
    setRGNSMovingDistance(response.distance);
    setRGNSMovingAC(response.angChg);
    setRGNSMovingSC(response.speedChg);
    setRGNSMovingSR(response.msavRec);
    setRGNSMovingSP(response.msendPeriod);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.RoamingGsmNetworkSettings) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Roaming GSM Network Settings"
      description="Data acquisition parameters for roaming GSM network."
      date={updatedDate}
      onSendAction={sendRoamingGSMNetwork}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="sub-panel w36">
            <p>Vehicle on Stop</p>
            <TextInput
              name="Data Recording Timeout"
              tooltip="Time interval to acquire new record, 1 - 259200 seconds, 0 means disable recording data"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={rgns_vehicle_drt}
              onChange={(value) => setRGNSVehicleDRT(value)} />
            <TextInput
              name="Saved Records"
              tooltip="Minimal number of records in one data packet that can be send to server, 1 - 255. Has higher priopity than [Send Period]"
              type="number"
              className="justify-content-between"
              value={rgns_vehicle_sr}
              onChange={(value) => setRGNSVehicleSR(value)} />
            <TextInput
              name="Send Period"
              tooltip="Frequency of sending data to server, 0 - 259200 seconds. Has lower priority than [Saved Records]"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={rgns_vehicle_sp}
              onChange={(value) => setRGNSVehicleSP(value)} />
          </div>
          <div className="sub-panel w36">
            <p>Vehicle Moving</p>
            <TextInput
              name="Timeout"
              tooltip="Time interval to acquire new record, 1 - 259200 seconds, 0 means disable recording data by min period"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={rgns_moving_timeout}
              onChange={(value) => setRGNSMovingTimeout(value)} />
            <TextInput
              name="Distance"
              tooltip="Minimal distance to acquire new record, 1 - 65535 meters, 0 means disable recording data by min distance"
              type="number"
              suffix="Meters"
              className="justify-content-between"
              value={rgns_moving_distance}
              onChange={(value) => setRGNSMovingDistance(value)} />
            <TextInput
              name="Angle Change"
              tooltip="Minimal angle change to acquire new record, 1 - 180 degrees, 0 means disable recording data by min angle change. Valid only if vehicle speed is more than 10 km/h"
              type="number"
              suffix="Degrees"
              className="justify-content-between"
              value={rgns_moving_ac}
              onChange={(value) => setRGNSMovingAC(value)} />
            <TextInput
              name="Speed Change"
              tooltip="Minimal speed change to acquire new record, 1 - 255 km/h, 0 means disable recording data by speed change"
              type="number"
              suffix="Km/h"
              className="justify-content-between"
              value={rgns_moving_sc}
              onChange={(value) => setRGNSMovingSC(value)} />
            <TextInput
              name="Saved Records"
              tooltip="Minimal number of records in one data packet that can be send to server, 1 - 255. Has higher priopity than [Send Period]"
              type="number"
              className="justify-content-between"
              value={rgns_moving_sr}
              onChange={(value) => setRGNSMovingSR(value)} />
            <TextInput
              name="Send Period"
              tooltip="Frequency of sending data to server, 0 - 259200 seconds. Has lower priority than [Saved Records]"
              type="number"
              suffix="Seconds"
              className="justify-content-between"
              value={rgns_moving_sp}
              onChange={(value) => setRGNSMovingSP(value)} />
          </div>
        </div>
      )} />
  );
};

export default PanelRoamingGSM;
