import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelStaticNavigation = ({token, deviceImei, commandResponse}) => {
  
  const [staticNavigation, setStaticNavigation] = useState(false)
  const [staticNavigationSource, setStaticNavigationSource] = useState("");
  const [updatedDate, setUpdatedDate] = useState("")

  const sendStaticNavigation = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.StaticNavigation,
      params: {
        on: staticNavigation ? "1" : "0",
        src: staticNavigationSource
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.StaticNavigation,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setStaticNavigation(response.on);
    setStaticNavigationSource(response.src);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.StaticNavigation) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Static Navigation"
      description="Static Navigation Mode is a filter, which filters out track jumps when the object is not moving."
      date={updatedDate}
      onSendAction={sendStaticNavigation}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="input-element border-0 d-flex justify-content-center mb-3">
            <Switch
              value={staticNavigation}
              onChange={(checked) => setStaticNavigation(checked)}
              prefix={"OFF"}
              suffix={"ON"}
            />
          </div>

          <SelectBox
            name="Static Navigation Source"
            value={staticNavigationSource}
            onChange={(e) => setStaticNavigationSource(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "1", label: "Movement" },
              { value: "2", label: "Ignition" },
              { value: "3", label: "Movement or Ignition" },
            ]}
          />
        </div>
      )} />
  );
};

export default PanelStaticNavigation;
