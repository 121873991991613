import React  from 'react'
import { Tooltip } from 'antd';

const Try = ({text}) => {
  return (
    <div className="position-relative">
      <Tooltip title={text} trigger="click" placement="right" arrow={false}>
        <img
          src="/assets/blueQuestion.svg" 
          alt="none"
          href="#"
          className="try-dropdown-img cursor-pointer"
          style={{ width: "2rem", height: "2rem" }}
        />
      </Tooltip>
    </div>
  )
}

export default Try