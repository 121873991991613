import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelIncomingCallAction = ({token, deviceImei, commandResponse}) => {

  const [incomingCallAction, setIncomingCallAction] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")

  const sendIncomingCallAction = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.IncomingCallAction,
      params: {
        action: incomingCallAction
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.IncomingCallAction,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setIncomingCallAction(response.action);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.IncomingCallAction) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Incoming Call Action"
      description="Set up network ping with a certain timeout to prevent link close by the operator."
      date={updatedDate}
      onSendAction={sendIncomingCallAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <TextInput
            name="Action"
            tooltip=""
            type="text"
            value={incomingCallAction}
            onChange={(value) => setIncomingCallAction(value)} />
        </div>
      )} />
  );
};

export default PanelIncomingCallAction;
