import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings, getCommandSettingsById } from "../../../services/axios";

import PanelCanParameters from "./PanelCanParameters";
import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg, socket } from "../../../socket";

const Can = () => {

  const token = useSelector((state) => state.auth.token);
  
  const getData = useLocation();
  const data = {
    deviceImei: getData?.state?.deviceImei,
  }

  const [openAllDoorsDate, setOpenAllDoorsDate] = useState("");
  const [openTrunkDate, setOpenTrunkDate] = useState("");
  const [blockEngineDate, setBlockEngineDate] = useState("");
  const [closeAllDoorsDate, setCloseAllDoorsDate] = useState("");
  const [flashLightDate, setFlashLightDate] = useState("");
  const [unblockEngineDate, setUnblockEngineDate] = useState("");

  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: data?.deviceImei
    }
    var res = await commandSetting(sendCommandData);
    return res;
  }

  const sendToggleCanControlOpenUnlockAllDoors = async () => {
    const res = await sendCommonRequest(cmdType.ToggleCanControlToOpenUnlockAllDoors, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setOpenAllDoorsDate(formatDateTime(new Date()));
    }
  }
  const sendToggleCanControlOpenUnlockTrunk = async () => {
    const res = await sendCommonRequest(cmdType.ToggleCanControlToOpenUnlockTrunk, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setOpenTrunkDate(formatDateTime(new Date()));
    }
  }

  const sendToggleCanControlToBlockEngine = async () => {
    const res = await sendCommonRequest(cmdType.ToggleCanControlToBlockEngine, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setBlockEngineDate(formatDateTime(new Date()));
    }
  }

  const sendToggleCanControlToCloselockAllDoors = async () => {
    const res = await sendCommonRequest(cmdType.ToggleCanControlToCloselockAllDoors, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setCloseAllDoorsDate(formatDateTime(new Date()));
    }
  }

  const sendToggleCanControlToFlashLights = async () => {
    const res = await sendCommonRequest(cmdType.ToggleCanControlToFlashLights, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setFlashLightDate(formatDateTime(new Date()));
    }
  }

  const sendToggleCanControlToUnblockEngine = async () => {
    const res = await sendCommonRequest(cmdType.ToggleCanControlToUnblockEngine, {});
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
      // setUnblockEngineDate(formatDateTime(new Date()));
    }
  }

  useEffect(async () => {
    let sendCommandData = {
      token: token,
      type: cmdType.ToggleCanControlToOpenUnlockAllDoors,
      devImei: data?.deviceImei,
      isLast: true,
    }
    
    let res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setOpenAllDoorsDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.ToggleCanControlToOpenUnlockTrunk;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setOpenTrunkDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.ToggleCanControlToBlockEngine;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setBlockEngineDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.ToggleCanControlToCloselockAllDoors;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setCloseAllDoorsDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.ToggleCanControlToFlashLights;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setFlashLightDate(formatDateTime(res.data.updatedAt));
    }

    sendCommandData.type = cmdType.ToggleCanControlToUnblockEngine;
    res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      setUnblockEngineDate(formatDateTime(res.data.updatedAt));
    }

  }, []);


  const [commandResponse, setCommandResponse] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      if (cmdData.deviceImei != data.deviceImei) {
        return;
      }
      const res = await getCommandSettingsById({
        token: token,
        commandId: cmdData.commandId,
      });
      if (res.status === 200) {
        setCommandResponse({ cmdType: cmdData.cmdType, ...(res.data) });
      } else {
        setCommandResponse(null);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);
    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.ToggleCanControlToOpenUnlockAllDoors) {
      setOpenAllDoorsDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.ToggleCanControlToOpenUnlockTrunk) {
      setOpenTrunkDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.ToggleCanControlToBlockEngine) {
      setBlockEngineDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.ToggleCanControlToCloselockAllDoors) {
      setCloseAllDoorsDate(formatDateTime(commandResponse.updatedAt));
    } 
    else if (commandResponse?.cmdType == cmdType.ToggleCanControlToFlashLights) {
      setFlashLightDate(formatDateTime(commandResponse.updatedAt));
    }
    else if (commandResponse?.cmdType == cmdType.ToggleCanControlToUnblockEngine) {
      setUnblockEngineDate(formatDateTime(commandResponse.updatedAt));
    }
  }, [commandResponse]);

  return (
    <div className="sub1-system-params-div3 row">
      <div className="col-md-5">
        <PanelCanParameters token={token} deviceImei={data?.deviceImei} commandResponse={commandResponse} />
      </div>
      <div className="col-md-7">
        <div className="row">
          <div className="col-md-6">
            <CommandSettingPanel
              title="Toggle CAN-CONTROL to open [unlock] all doors"
              date={openAllDoorsDate} 
              onSendAction={sendToggleCanControlOpenUnlockAllDoors} />
            <CommandSettingPanel
              title="Toggle CAN-CONTROL to open [unlock] trunk"
              date={openTrunkDate} 
              onSendAction={sendToggleCanControlOpenUnlockTrunk} />
            <CommandSettingPanel
              title="Toggle CAN-CONTROL to block engine"
              date={blockEngineDate} 
              onSendAction={sendToggleCanControlToBlockEngine} />
          </div>

          <div className="col-md-6">
            <CommandSettingPanel
              title="Toggle CAN-CONTROL to close [lock] all doors"
              date={closeAllDoorsDate} 
              onSendAction={sendToggleCanControlToCloselockAllDoors} />
            <CommandSettingPanel
              title="Toggle CAN-CONTROL to flash lights"
              description="One flash of all turn lights ordered trough accidental / blinking turn light switch"
              date={flashLightDate} 
              onSendAction={sendToggleCanControlToFlashLights} />
            <CommandSettingPanel
              title="Toggle CAN-CONTROL to unblock engine"
              date={unblockEngineDate} 
              onSendAction={sendToggleCanControlToUnblockEngine} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Can;
